export const DEF_INPUT_LEN = 464;
export const DEF_INPUT_LEN_QUARTS = 92;

export const DEF_LAYOUT = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const DEF_TAIL_LAYOUT = {
  wrapperCol: { offset: 8, span: 16 },
};
