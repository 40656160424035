import React from 'react';
import { Menu } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

function getMenuUrl(menuItem: any): any {
  if (!menuItem) {
    return null;
  }

  const url = menuItem.path || menuItem.redirect;
  if (url) {
    return url;
  }

  if (menuItem.children && menuItem.children.length) {
    for (const m of menuItem.children) {
      const url = getMenuUrl(m);

      if (url) {
        return url;
      }
    }
  }

  return null;
}


const TopMenu: React.FC<{
  menu: any;
  menuIndexes: any;
}> = ({ menu, menuIndexes }) => {

  const navigate = useNavigate();

  const selectMenu = (e: any) => {
    // == string or number
    const menuItem = menu.find((o: any) => o.id == e.key);
    const url = getMenuUrl(menuItem);

    if (url) {
      navigate(url);
    }
  }

  console.log(menu);

  return (
    <Menu
      theme="dark"
      onClick={selectMenu}
      mode="horizontal"
      selectedKeys={[`${menuIndexes[0]}`]}
    >
      {
        menu?.map((o: any) => (
          <Menu.Item
            icon={o.icon ? <img style={{ width: 16, height: 16 }} src={`/assets/icons/${o.icon}.png`} /> : <ProfileOutlined />}
            key={o.id}
          >
            {o.title}
          </Menu.Item>
        ))
      }
    </Menu>
  )
}

export default React.memo(TopMenu);
