import React from 'react'

const Error404: React.FC = () => {
  return (
    <div>
      404
    </div>
  )
}

export default Error404
