import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { FolderOutlined, ProfileOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';

const { SubMenu } = Menu;

function selectedMenu(menu: any, menuIndexes: any) {
  if (!menuIndexes || !menuIndexes[0]) {
    return [];
  }

  const menuItem = menu.find((o: any) => o.id === menuIndexes[0]);
  return (menuItem && menuItem.children) || [];
}

const SiderMenu: React.FC<{
  menu: any,
  menuIndexes: any,
}> = ({ menu, menuIndexes }) => {
  console.log(menu, menuIndexes);

  const subMenu = useMemo(() => selectedMenu(menu, menuIndexes), [menu, menuIndexes]);
  const location = useLocation();

  const pathname = location.pathname;
  const navigate = useNavigate();

  function goto(url: string) {
    if (pathname !== url) {
      navigate(url);
    }
  }

  return (
    <Menu
      key={menuIndexes[0] || '0'}
      mode="inline"
      selectedKeys={[`${menuIndexes[2] || menuIndexes[1]}`]}
      defaultOpenKeys={[`${menuIndexes[1]}`]}
    >
      {
        subMenu.map((o: any) => (
          o.children && o.children.length > 0 ? (
            <SubMenu key={o.id} icon={o.icon ? <img style={{ width: 16, height: 16 }} src={`/assets/icons/${o.icon}.png`} /> : <FolderOutlined />} title={o.title}>
              {
                o.children.map((oo: any) => (
                  <Menu.Item
                    icon={o.icon ? <img style={{ width: 16, height: 16 }} src={`/assets/icons/${o.icon}.png`} /> : <ProfileOutlined />}
                    onClick={() => goto(oo.path)} key={oo.id}>{oo.title}</Menu.Item>
                ))
              }
            </SubMenu>
          ) : (
            <Menu.Item
              icon={o.icon ? <img style={{ width: 16, height: 16 }} src={`/assets/icons/${o.icon}.png`} /> : <ProfileOutlined />}
              onClick={() => goto(o.path)} key={o.id}>{o.title}</Menu.Item>
          )
        ))
      }
    </Menu>
  );
}

export default React.memo(SiderMenu);
