import JsonApi from '../../utils/jsonapi';
import CachePromise from '../../utils/cachepromise';
import apiurl from '../apiurl';
import { message } from 'antd';

class Service extends JsonApi {

  constructor(props) {
    super(props);

    try {
      this.nameMap = {};
      const cacheNameMap = JSON.parse(localStorage.getItem('adcdNameMap'));
      if (cacheNameMap) {
        this.nameMap = cacheNameMap;
      }
    } catch (e) {
      console.error(e);
    }

    this.data = undefined;
    this.cacheListPromise = new CachePromise(() => this.httppost(apiurl.base.adinfo.list, { level: 4 }), Number.MAX_VALUE);
  }

  /**
   * 具有缓存的list
   */
  async listcache() {
    return this.cacheListPromise.get();
  }

  // 永远缓存
  async listc() {
    if (this.data) {
      return this.data;
    }
    const { data } = await this.listcache();
    if (Array.isArray(data) && data.length > 0) {
      this.data = data;
      data.forEach(o => {
        this.nameMap[o.adcd] = o.adnm;
      });
      localStorage.setItem('adcdNameMap', JSON.stringify(this.nameMap));
      return data;
    }
    return [];
  }

  getFromCache(adcd) {
    if (this.data) {
      return this.data.find(o => o.adcd === adcd);
    }
    return null;
  }

  adnm = (adcd) => {
    if (!adcd) {
      return undefined;
    }
    return this.nameMap[adcd];
  }

  adnmCity = (adcd) => {
    if (!adcd) {
      return undefined;
    }
    if (adcd.endsWith('0000000000000')) {
      return undefined;
    } else if (adcd.endsWith('00000000000')) {
      return this.nameMap[adcd];
    }
    return this.nameMap[`${adcd.substr(0, 4)}00000000000`];
  }

  adnmXian = (adcd) => {
    if (!adcd) {
      return undefined;
    }
    if (adcd.endsWith('00000000000')) {
      return undefined;
    } else if (adcd.endsWith('000000000')) {
      return this.nameMap[adcd];
    }
    return this.nameMap[`${adcd.substr(0, 6)}000000000`];
  }

  adnmZhen = (adcd) => {
    if (!adcd) {
      return undefined;
    }
    if (adcd.endsWith('000000000')) {
      return undefined;
    } else if (adcd.endsWith('000000')) {
      return this.nameMap[adcd];
    }
    return this.nameMap[`${adcd.substr(0, 9)}000000`];
  }

  page = async (params = {}) => {
    const { data, code, msg } = await this.httppost(apiurl.base.adinfo.find, params) || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return { list: [], totalRow: 0 };
    }
    return { list: data.records, totalRow: data.total };
  }

  adlist = async (params) => {
    const { data, code, msg } = await this.httppost(apiurl.base.adinfo.adlist, params) || {};

    if (code !== 200) {
      message.error(msg || '请求失败');
      return [];
    } else {
      return data;
    }
  }

  aboveList = async (params) => {
    const { data, code, msg } = await this.httppost(apiurl.base.adinfo.aboveList, params) || {};

    if (code !== 200) {
      message.error(msg || '请求失败');
      return [];
    } else {
      return data;
    }
  }
}

const adinfoService = new Service({ apiurl: { ...apiurl.adinfo } });
export default adinfoService;
