import {
  SearchOutlined
} from '@ant-design/icons';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { Button, Card, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import { PageTableContext } from '../../../components/crud/usePageTable';
import { Adinfo } from '../def';

type IProps = {
  pagerCtx: PageTableContext<Adinfo>;
}

const DataTable: React.FC<IProps> = ({ pagerCtx }) => {

  const columns = useMemo<ColumnsType<Adinfo>>(() => [
    { key: 'adcd', dataIndex: 'adcd', title: '政区编码', width: 140 },
    { key: 'adnm', dataIndex: 'adnm', title: '政区名称', width: 120 },
    { key: 'city', dataIndex: 'city', title: '所属市', width: 120 },
    { key: 'xian', dataIndex: 'xian', title: '所属县', width: 120 },
    { key: 'zhen', dataIndex: 'zhen', title: '所属乡镇', width: 120 },
    { key: 'ptcount', dataIndex: 'ptcount', title: '总人口', width: 100, align: 'right' },
    { key: 'hhtcount', dataIndex: 'hhtcount', title: '总户口', width: 100, align: 'right' },
    { key: 'htcount', dataIndex: 'htcount', title: '房屋数', width: 100, align: 'right' },
    { key: 'land', dataIndex: 'land', title: '土地面积', width: 100, align: 'right' },
    { key: 'pland', dataIndex: 'pland', title: '耕地面积', width: 100, align: 'right' },
  ], []);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={async (values) => {
            pagerCtx.search({ search: values });
          }}
          submitter={false}
        >
          <ProFormText label="政区名称" name="adnm" />

          <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="adcd" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable;
