/* eslint-disable no-useless-escape */

// 电话正则(固定电话或手机号)
export const phoneValidator = (_: any, val: string): Promise<Error | void> => {
    if (!val) {
        return Promise.reject(new Error('电话不能为空'));
    }
    if (!(/^(1[3456789]|9[28])\d{9}$/).test(val) && !(/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/).test(val)) {
        return Promise.reject(new Error('电话格式不正确'));
    }
    return Promise.resolve();
};

//一个或多个大写字母正则
export const upperCaseValidator = (_: any, val: string): Promise<Error | void> => {
    if (!val) {
        return Promise.reject(new Error('类别字母不能为空'));
    }
    if (!(/^[A-Z]+$/).test(val)) {
        return Promise.reject(new Error('字母格式不正确'));
    }
    return Promise.resolve();
};
