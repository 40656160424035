import React from 'react';
import { Tree} from 'antd';


class StrictlyTreeSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            expandedKeys: props.value || [],
            checkedKeys: props.value || [],
            autoExpandParent: true,
        }
    }

   onExpand = (expandedKeys) => {
     this.setState({ expandedKeys, autoExpandParent: false  });
  };

   onCheck = ({ checked, halfCheckedKeys }) => {
     const keys = [...checked];
     if (halfCheckedKeys && halfCheckedKeys.length > 0) {
         keys.push(...halfCheckedKeys);
     }
     this.setState({ checkedKeys: keys });
     if (this.props.onChange) {
         this.props.onChange(keys);
     }
  };

   render() {
       const { expandedKeys, autoExpandParent, checkedKeys, data } = this.state;
       if (!data || !data.length) return null;

       return (
           <div style={{ border: '1px solid #d9d9d9', padding: 16, height: 300, overflowY: 'auto' }}>
               <Tree
                   checkable
                   checkStrictly
                   onExpand={this.onExpand}
                   expandedKeys={expandedKeys}
                   autoExpandParent={autoExpandParent}
                   onCheck={this.onCheck}
                   checkedKeys={checkedKeys}
                   treeData={data}
               />
           </div>
       )
   }
}

export default StrictlyTreeSelect
