import { useState, useEffect, useRef, useReducer } from "react";


export type RequestContext<DataType = any> = {
  data?: DataType,
  error?: any,
  loading: boolean,
  refresh: (params?: any) => void
}


const useRequest = <DataType = any>(p: (parame?: any) => Promise<DataType>, initialParam?: any): RequestContext<DataType> => {
  const [data, setData] = useState<DataType | undefined>()
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const abort = useRef(false);
  const [_, r] = useReducer(s => s + 1, 0);
  const params = useRef(initialParam);

  useEffect(() => {
    const doFetch = async () => {
      setLoading(true);
      abort.current = false;
      try {
        const data = await p(params.current);
        if (!abort.current) {
          setData(data);
        }
      } catch (e) {
        if (!abort.current) {
          setError(e);
        }
      } finally {
        if (!abort.current) {
          setLoading(false);
        }
      }
    };
    doFetch();
    return () => {
      abort.current = true;
    };
  }, [_]); // eslint-disable-line

  return {
    data,
    error,
    loading,
    refresh: (p?: any) => {
      params.current = p;
      r();
    }
  };
};

export default useRequest;
