import { createModel } from "@rematch/core";
import moment from "moment";
import { RootModel } from "../../";
import { UserGpsState, UserGpsState_InfoView } from "../../def";


export const usergps = createModel<RootModel>()({
  state: {
    selected: [],
    time: moment().format('YYYY-MM-DD'),
    infoView: null,
  } as UserGpsState,

  reducers: {
    addUser(state, user: { id: number, name: string }): UserGpsState {
      const selected = state.selected;
      if (selected.find(s => s.id == user.id)) {
        return state;
      }

      return { ...state, selected: [...selected, user] };
    },

    delUser(state, id: number): UserGpsState {
      const selected = state.selected.filter(o => o.id !== id);
      return { ...state, selected };
    },

    setTime(state, time: string) {
      return { ...state, time };
    },

    setInfoView(state, obj: UserGpsState_InfoView | null) {
      return { ...state, infoView: obj };
    }
  },

  effects: {

  }
});
