import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import DashboardLayout from '../layouts/DashboardLayout';
import Login from './auth/Login';
import RolePage from './auth/Role';
import UserPage from './auth/User';
import PermissionPage from './auth/permission';
import DeptPage from "./auth/dept";

import SysLoginLogPage from './sys/sysLoginLog';
import AdinfoPage from './base/adinfo';

import Error404 from "./errors/Error404";
import UserMsgPage from './basic/usermsg';
import UserGpsPage from './basic/usergps';
import TrainResPage from './basic/trainres';
import ZrrPage from './base/zrr';
import OnlineState from './base/onlineState'
import OnlineStatistics from './base/onlineStatistics'

const AppRouters: React.FC = () => {
  return useRoutes([
    { path: '/login', element: <Login /> },
    {
      path: '/auth', element: <DashboardLayout />, children: [
        { path: 'user', element: <UserPage /> },
        { path: 'role', element: <RolePage /> },
        { path: 'permission', element: <PermissionPage /> },
        { path: 'dept', element: <DeptPage /> },
        { path: '*', element: <Error404 /> },
      ]
    },
    {
      path: '/sys', element: <DashboardLayout />, children: [
        { path: 'sysLoginLog', element: <SysLoginLogPage /> },
        { path: '*', element: <Error404 /> },
      ]
    },
    {
      path: '/base', element: <DashboardLayout />, children: [
        { path: 'adinfo', element: <AdinfoPage /> },
        { path: 'usermsg', element: <UserMsgPage /> },
        { path: 'usergps', element: <UserGpsPage /> },
        { path: 'trainres', element: <TrainResPage /> },
        { path: 'p', element: <ZrrPage /> },
        { path: 'onlineState', element: <OnlineState /> },
        { path: 'onlineStatistics', element: <OnlineStatistics /> },
        { path: '*', element: <Error404 /> },
      ]
    },
    { path: '/', element: <Navigate to="/login" /> },
  ]);
};

export default AppRouters
