import { Card, Descriptions } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CrudContext } from '../../../components/crud/useCrud';
import apiurl from '../../../service/apiurl';
import useRefresh from '../../../utils/useRefresh';
import StatItem from './components/StatItem';

const StatToday: React.FC<{
  crudCtx: CrudContext
}> = ({ crudCtx }) => {

  const [data, setData] = useState<any>({});
  const [tm, setTm] = useState<null | string>(null);
  const t = useRefresh(30 * 1000);

  useEffect(() => {
    axios.post(apiurl.usermsg.statToday).then(resp => {
      if (resp.data?.code == 200) {
        setData(resp.data?.data || {})

        setTm(moment().format('YYYY-MM-DD HH:mm:ss'));
      }
    })
  }, [t]);



  return (
    <Card
      title="当日信息统计"
    >
      <Descriptions column={1} bordered>
        <Descriptions.Item label="消息总数">
          <StatItem name="total" time={0} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="未读消息数">
          <StatItem name="noRead" crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超5分钟未读">
          <StatItem name="noRead5m" time={5 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超10分钟未读">
          <StatItem name="noRead10m" time={10 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超20分钟未读">
          <StatItem name="noRead20m" time={20 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超30分钟未读">
          <StatItem name="noRead30m" time={30 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超40分钟未读">
          <StatItem name="noRead40m" time={40 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超50分钟未读">
          <StatItem name="noRead50m" time={50 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>
        <Descriptions.Item label="超60分钟未读">
          <StatItem name="noRead60m" time={60 * 60 * 1000} crudCtx={crudCtx} data={data} />
        </Descriptions.Item>


        <Descriptions.Item label="请求时间">
          {tm}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default StatToday