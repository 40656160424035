
import { Modal } from 'antd';
import RoleForm from '../../auth/User/RoleForm';
import AdcdTree from './AdcdTree';
import AForm from './AForm';
import DataFormNew from './DataFormNew';
import DataTable from './DataTable';
import PForm from './PForm';
import SearchBar from './SearchBar';
import { usePageContext } from './_';

const ZrrPage = () => {

  const ctx = usePageContext();


  const { crud, pager } = ctx;

  return (
    <div className="content-root">
      {
        crud.mode === 'add' ? (
          <Modal
            visible
            footer={null}
            style={{ top: 20 }}
            title={'新增责任人'}
            onCancel={() => crud.goto(null, null)}
          >
            <DataFormNew ctx={ctx} />
          </Modal>

        ) : null
      }
      {
        crud.mode === 'editP' ? (
          <Modal
            visible
            footer={null}
            style={{ top: 20 }}
            title={'修改责任人信息'}
            onCancel={() => crud.goto(null, null)}
          >
            <PForm key={crud.record.id} ctx={ctx} />
          </Modal>

        ) : null
      }
      {
        crud.mode === 'editA' ? (
          <Modal
            visible
            footer={null}
            style={{ top: 20 }}
            title={'修改账户信息'}
            onCancel={() => crud.goto(null, null)}
          >
            <AForm key={crud.record.id} ctx={ctx} />
          </Modal>

        ) : null
      }
      {
        crud.mode === 'allotRole' ? (
          <RoleForm key={crud.record.id} crudCtx={crud as any} pagerCtx={pager} />
        ) : null
      }
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 250 }}>
          <AdcdTree ctx={ctx} />
        </div>
        <div style={{ width: 16 }}></div>
        <div style={{ flexGrow: 1 }}>
          <SearchBar ctx={ctx} />
          <div className="card-h-margin" />
          <DataTable ctx={ctx} />
        </div>
      </div>
    </div>
  )
}

export default ZrrPage