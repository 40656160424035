import React from 'react';
import useCrud from '../../../components/crud/useCrud';
import DataTable from './DataTable';
import StatToday from './StatToday';



const UserMsgPage: React.FC = () => {

  const crud = useCrud<'search'>();


  return (
    <div className="content-root">
      {
        crud.mode === 'search' ? (
          <DataTable crudCtx={crud} />
        ) : (
          <StatToday crudCtx={crud} />
        )
      }
    </div>
  )
};

export default UserMsgPage
