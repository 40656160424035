import { objEnum } from '../service/appconsts';
import deptService from "../service/auth/dept";
import roleService from '../service/auth/role';
import permissionService from "../service/auth/permission";
import { TreeNode } from '../service/def';
import { SysDept, SysPermission, SysRole } from "../views/auth/def";

export type labelValueItem = {
    label: string,
    value: number | string
}

export const getLabelValueByObj = (obj: objEnum): labelValueItem[] => {
     return Object.keys(obj).map(a => Number(a))
         .sort().map(m => ({ value: m, label: obj[m] }))
};

export const delEmptyChildren = (data: any) => {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    data.forEach(a => {
        if (a.children && Array.isArray(a.children) && a.children.length > 0) {
            delEmptyChildren(a.children);
        } else {
            delete a.children;
        }
    });
};

const permissionTreeDataFormat = (treeJson: SysPermission[]): TreeNode[] => {
    if (!Array.isArray(treeJson) || treeJson.length <= 0) {
        return []
    }
    const res: TreeNode[] = [];
    treeJson.forEach(({ id, title, type, children }) => {
     if (id && title) {
         res.push({
             id,
             key: String(id),
             type,
             title,
             children: (children && children.length > 0) ? permissionTreeDataFormat(children) : []
         })
     }
    });
    return res
};

// 权限树
export const getPermissionTreeData = async (): Promise<TreeNode[]> => {
    const ret: SysPermission[] = await permissionService.list2();
    if (ret && Array.isArray(ret) && ret.length > 0) {
        const children  = permissionTreeDataFormat(ret);
        const ops = {
            id: 0,
            key: '0',
            type: 0,
            title: '根目录',
            children
        };
        return [ops];
    }
    return [
        {
            id: 0,
            key: '0',
            type: 0,
            disabled: true,
            title: '根目录',
            children: []
        }
    ]
};

const deptTreeDataFormat = (treeJson: SysDept[]): TreeNode[] => {
    if (!Array.isArray(treeJson) || treeJson.length <= 0) {
        return []
    }
    const res: TreeNode[] = [];
    treeJson.forEach(({ id, deptName, children }) => {
     if (id && deptName) {
         res.push({
             id,
             key: String(id),
             title: deptName,
             children: (children && children.length > 0) ? deptTreeDataFormat(children) : []
         })
     }
    });
    return res
};

// 部门树
export const getDeptTreeData = async (): Promise<TreeNode[]> => {
    const ret: SysDept[] = await deptService.deptTreeList();
    if (ret && Array.isArray(ret) && ret.length > 0) {
        const children  = deptTreeDataFormat(ret);
        const ops = {
            id: 0,
            key: '0',
            title: '根目录',
            children
        };
        return [ops];
    }
    return [
        {
            id: 0,
            key: '0',
            disabled: true,
            title: '根目录',
            children: []
        }
    ]
};

// 角色options
export const getRoleOptions = async (): Promise<labelValueItem[]> => {
     const list: SysRole[] = await roleService.roleList();
     return list.map(({ id, roleName }) => ({ label: roleName, value: String(id) }));
};

const deptUserTreeDataFormat = (treeJson: SysDept[]): TreeNode[] => {
    if (!Array.isArray(treeJson) || treeJson.length <= 0) {
        return []
    }
    const res: TreeNode[] = [];
    treeJson.forEach(({ id, key, deptName, type, parentId, children }) => {
       if (id && deptName) {
           res.push({
               id,
               key: `${parentId}_${id}`,
               type,
               parentId,
               title: deptName,
               children: (children && children.length > 0) ? deptUserTreeDataFormat(children) : []
           })
       }
    });
    return res
};

// 部门人员树
export const getDeptUserTreeData = async (): Promise<TreeNode[]> => {
    const ret: SysDept[] = await deptService.deptUserTree();
    if (ret && Array.isArray(ret) && ret.length > 0) {
        const children  = deptUserTreeDataFormat(ret);
        const ops = {
            id: 0,
            key: '0',
            parentId: -1,
            type: 0,
            title: '整个公司',
            children
        };
        return [ops];
    }
    return [
        {
            id: 0,
            key: '0',
            parentId: -1,
            type: 0,
            disabled: true,
            title: '整个公司',
            children: []
        }
    ]
};

