import { Button, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import DeptTreeSelect from '../../../components/common/tree/TreeSelect';
import apiurl from '../../../service/apiurl';
import { getDeptTreeData } from '../../../utils/sysutils';
import useRequest from '../../../utils/useRequest';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { SysUserDept } from '../def';
import { ICompProps } from './common';

const DeptForm: React.FC<ICompProps> = ({
  crudCtx,
  pagerCtx
}) => {

  const { data } = useRequest(getDeptTreeData);

  const [deptIds, setDeptIds] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async () => {
    if (deptIds.length === 0) {
      return;
    }
    const userId: number = crudCtx.record.id;
    const params: SysUserDept = { userId, ids: deptIds };

    saveOrUpdateInPager(apiurl.upgramurl.userDept.save, params, pagerCtx, crudCtx, setLoading);
  }

  return (
    <Modal
      visible
      title='分配角色'
      onCancel={() => crudCtx.goto(null, null)}
      footer={[
        <Button key="submit" type="primary" disabled={deptIds.length === 0} onClick={submit} loading={loading}>提交</Button>,
        <Button key="back" onClick={() => crudCtx.goto(null, null)}>取消</Button>
      ]}
    >
      {
        data ? (
          <DeptTreeSelect data={data} value={crudCtx?.record?.deptIds || []} onChange={e => setDeptIds(e)} />
        ) : null
      }
    </Modal>
  )
}

export default DeptForm