import { useState } from "react";



export type CrudContext<modes = any> = {
  mode: modes | null;
  record: any;
  goto: (mode: modes | null, r: any) => void;
}


export default function useCrud<modes = any>(params?: {
  mode?: modes;
  record?: any;
}): CrudContext<modes> {
  const [state, setState] = useState<{
    mode: modes | null;
    record: any;
  }>({
    mode: params?.mode || null,
    record: params?.record,
  })

  const goto = (mode: modes | null, record: any) => {
    setState({ mode, record })
  };

  return {
    ...state,
    goto,
  }
}
