import { TreeSelect } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../models/store';
import adinfoService from '../../../service/base/adinfo';
import { Adinfo } from '../def';
import { DataNode, updateTreeData } from './AdcdTree';

const AdcdTreeSelect: React.FC<{
  value?: { adcd: string; adnm: string };
  onChange?: (val: { adcd: string; adnm: string }) => void;
}> = ({ value, onChange }) => {
  const user = useSelector((s: RootState) => s.auth.user);

  const [treeData, setTreeData] = useState<DataNode[]>(() => user ? [{
    key: user.adcd,
    title: user.adnm,
    value: user.adcd,
  }] : []);

  const onTreeLeafLoadData = async ({ key, value }: any) => {

    if (!/000000$/.test(value)) {
      return;
    }

    const list: Adinfo[] = await adinfoService.adlist({ adcd: value });

    if (list && list.length) {
      const newNodes: DataNode[] = list.map(({ adcd, adnm }) => ({
        title: adnm,
        key: adcd,
        value: adcd,
        isLeaf: !adcd.endsWith('000000')
      }))

      console.log(newNodes)
      setTreeData(origin => updateTreeData(origin, key, newNodes));
    }
  }

  return (
    <TreeSelect
      onChange={(val, labelList) => {
        onChange && onChange({
          adcd: val,
          adnm: labelList[0] as string
        })
      }}
      placeholder={value?.adnm || '请选择行政区划'}
      //dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      //treeDataSimpleMode
      loadData={onTreeLeafLoadData}
      treeData={treeData}
    />

  )
}

export default AdcdTreeSelect