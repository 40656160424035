import { httppost } from '../utils/request';
import apiurl from './apiurl';
import config from "../config";

class Login {

  /**
   * 验证用户权限
   * @param {object} params
   */
  async auth({ name, password, type, brand, browserVersion, deviceId, latitude, longitude, model, operatingSystem }) {
    const { code, data, msg } = await httppost(apiurl.upgramurl.login.auth, {
      name,
      password,
      type,
      brand,
      browserVersion,
      deviceId,
      latitude, longitude, model, operatingSystem
    }) || {};
    if (data && data.token) {
      this.setLoginInfo(data);
    }

    return { code, msg, data };
  }

  /**
   * 判断是否登陆过
   * 已经登陆，返回ture
   */
  checkLoginInfo() {
    const token = sessionStorage.getItem('token');
    return token && token.length > 0;
  }

  setLoginInfo(data) {
    const { token, name, user, id, sysRoles, sysDepts } = data;
    const roleNames = sysRoles.map(m => m.roleName).join(',');
    const roleIds = sysRoles.map(m => m.id).join(',');
    const deptNames = sysDepts.map(m => m.deptName).join(',');
    const deptIds = sysDepts.map(m => m.id).join(',');

    sessionStorage.setItem('token', token);
    sessionStorage.setItem('username', user);
    sessionStorage.setItem('account', name);
    sessionStorage.setItem('userid', id);
    sessionStorage.setItem('useRoleNames', roleNames);
    sessionStorage.setItem('useRoleIds', roleIds);
    sessionStorage.setItem('useDeptNames', deptNames);
    sessionStorage.setItem('useDeptIds', deptIds);
    sessionStorage.setItem('sysDeptsArray', JSON.stringify(sysDepts));

    sessionStorage.setItem('__usereinfo__', JSON.stringify(data));
  }

  removeLoginInfo() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('account');
    sessionStorage.removeItem('userid');
    sessionStorage.removeItem('useRoleNames');
    sessionStorage.removeItem('useRoleIds');
    sessionStorage.removeItem('useDeptNames');
    sessionStorage.removeItem('useDeptIds');
    sessionStorage.removeItem('sysDeptsArray');

    sessionStorage.removeItem('__usereinfo__');

    config.menuConstants.menu = [];
    config.menuConstants.menuTree = [];
    config.menuConstants.menuIdMap = {};
    config.menuConstants.menuUrlMap = {};
  }

  getUser() {
    const strUser = sessionStorage.getItem('__usereinfo__');
    if (!strUser) {
      return null;
    }
    try {
      const obj = JSON.parse(strUser);
      if (obj.id && obj.token) {
        return obj;
      }
    } catch (e) { return null }
  }

  getUserName() {
    return sessionStorage.getItem('username');
  }

  getUserAccount() {
    return sessionStorage.getItem('account');
  }

  getRoleNames() {
    const useRoleNames = sessionStorage.getItem('useRoleNames');
    if (useRoleNames) {
      return useRoleNames?.split(',');
    }
    return null;
  }

  getRoleIds() {
    const useRoleIds = sessionStorage.getItem('useRoleIds');
    if (useRoleIds) {
      return useRoleIds.split(',');
    }
    return null;
  }

  getDeptNames() {
    const useDeptNames = sessionStorage.getItem('useDeptNames');
    if (useDeptNames) {
      return useDeptNames.split(',');
    }
    return null;
  }

  getDeptIds() {
    const useDeptIds = sessionStorage.getItem('useDeptIds');
    if (useDeptIds) {
      return useDeptIds.split(',');
    }
    return null;
  }

  getDeptArr() {
    let deptArr = sessionStorage.getItem('sysDeptsArray');
    if (deptArr) {
      deptArr = JSON.parse(deptArr);
      return deptArr.map(m => ({ id: m.id, deptName: m.deptName }));
    }
    return [];
  }

  // 是否管理员
  isAdmin() {
    const roleIds = this.getRoleIds();
    return roleIds?.includes('4') || false;
  }

}

const loginService = new Login();
export default loginService;
