import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import { DelOpButton, EditOpButton } from '../../../components/crud/OpButton';
import apiurl from '../../../service/apiurl';
import { hiddenType, systype } from '../../../service/appconsts';
import { delInPager } from '../../../utils/utils';
import { SysPermission } from '../def';
import { ICompProps } from './common';


const DataTable: React.FC<ICompProps> = ({ requestCtx, crudCtx }) => {

  const columns = useMemo<ColumnsType<SysPermission>>(() => [
    { title: '菜单类型', key: 'type', dataIndex: 'type', render: val => systype[val] },
    { title: '菜单名称', key: 'title', dataIndex: 'title' },
    {
      title: '菜单路由', key: 'path', dataIndex: 'path', render: (val, text) => {
        if (text.type === 0) return text.redirect;
        return val;
      }
    },
    { title: '菜单排序号', key: 'orderNum', dataIndex: 'orderNum' },
    { title: '是否隐藏', key: 'hidden', dataIndex: 'hidden', render: val => hiddenType[val] },
    { title: '创建时间', key: 'createTime', dataIndex: 'createTime' },
    {
      title: '操作', key: '_', render: rec => (
        <Space split={<Divider type="vertical" />}>
          <EditOpButton onClick={() => crudCtx.goto('edit', rec)} />
          <DelOpButton onClick={() => delInPager(`${apiurl.upgramurl.permission.del}`, { id: rec.id }, requestCtx)} />
        </Space>
      )
    }
  ], [crudCtx]);

  return (
    <>
      <Card
        extra={<Button icon={<PlusOutlined />} onClick={() => crudCtx.goto('add', undefined)}>新建</Button>}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={requestCtx.data}
          loading={requestCtx.loading}
          size='small'
          pagination={false}
          bordered
        />
      </Card>
    </>
  )

};

export default DataTable
