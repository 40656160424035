import { Modal } from 'antd';
import React from 'react'
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { paginate } from '../../../utils/utils';
import { TrainRes } from '../def';
import DataForm from './DataForm';
import DataTable from './DataTable';
import { CrudType } from './_';

const TrainResPage = () => {

  const crud = useCrud<CrudType>();
  const pager = usePageTable<TrainRes>((opt) => paginate(apiurl.trainres.find, opt));

  return (
    <div className="content-root">
      {
        crud.mode && ['add'].includes(crud.mode) ? (
          <Modal
            visible
            footer={null}
            style={{ top: 20 }}
            title='上传资料'
            onCancel={() => crud.goto(null, null)}
          >
            <DataForm crudCtx={crud} pagerCtx={pager} />
          </Modal>
        ) : null
      }
      <DataTable crudCtx={crud} pagerCtx={pager} />
    </div>
  )
}

export default TrainResPage