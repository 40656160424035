import { Tree } from 'antd';
import React, { useState } from 'react';
import { TreeNode } from '../../../service/def';

interface IProps {
    data: TreeNode[];
    value: React.Key[];
    onChange: (value: React.Key[]) => void;
}

const TreeSelect: React.FC<IProps> = ({ value, onChange, data }) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(value);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[] | { checked: React.Key[]; halfChecked: React.Key[]; }>(value);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(value);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checked: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[]; }) => {
      setCheckedKeys(checked);
      onChange(checked as React.Key[]);
  };

  const onSelect = (selectedKeysValue: React.Key[]) => {
     setSelectedKeys(selectedKeysValue);
     onChange(selectedKeysValue);
  };

  return data.length ? (
        <div style={{ border: '1px solid #d9d9d9', padding: 16, height: 300, overflowY: 'auto' }}>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={data}
          />
        </div>
  ) : null
};

export default TreeSelect
