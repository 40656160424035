import React from 'react';
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import { SysLoginLog } from '../def';
import DataTable from './DataTable';
import { page } from "../../../service/sys/sysLoginLog";
import { SearchOption } from "../../../service/def";

const SysLoginLogPage: React.FC = () => {

  const crud = useCrud();

  const pager = usePageTable<SysLoginLog>(async (params?: SearchOption) => await page(params));

  return (
    <div className="content-root">
        <DataTable crudCtx={crud} pagerCtx={pager} />
    </div>
  )
};

export default SysLoginLogPage
