import {
  SearchOutlined
} from '@ant-design/icons';
import ProForm, { ProFormText, ProFormDateRangePicker } from '@ant-design/pro-form';
import { Button, Card, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo } from 'react';
import { PageTableContext } from '../../../components/crud/usePageTable';
import { Adinfo } from '../def';
import moment from 'moment';


type IProps = {
  pagerCtx: PageTableContext<Adinfo>;
  defaultOption:any
}

const DataTable: React.FC<IProps> = ({ pagerCtx,defaultOption }) => {



  const columns = useMemo<ColumnsType<Adinfo>>(() => [
    { key: 'data', dataIndex: 'data', title: '日期', width: 140 },
    { key: 'allNumber', dataIndex: 'allNumber', title: '总人数', width: 120 },
    { key: 'onlineNumber', dataIndex: 'onlineNumber', title: '在线人数', width: 120 },
    { key: '', dataIndex: '', title: '离线人数', width: 120,render:(v:any,a:any)=>(Number(a.allNumber)-Number(a.onlineNumber)) },
  ], []);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={async (values:any) => {
            const {time,...rec} = values
            const params:any = {...rec}
            if(time){
              params.bgTm = moment(time[0]).format('YYYY-MM-DD HH:mm:ss')
              params.endTm = moment(time[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            pagerCtx.search({ search: params });
          }}
          submitter={false}
        >
          <ProFormDateRangePicker
            name="time"
            label="时间"
            initialValue={[moment(defaultOption.bgTm),moment(defaultOption.endTm)]}
          />

          <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="id" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable;
