import React from 'react'
import { CrudContext } from '../../../../components/crud/useCrud';

const StatItem: React.FC<{
  name: string;
  data: any;
  crudCtx: CrudContext<'search'>,
  time?: number
}> = ({ name, data, crudCtx, time }) => {
  const goto = () => {
    console.log(data);
    crudCtx.goto('search', {
      noReadTime: `${time}`,
      stm: data.stm
    })
  }

  return (
    <div style={{ width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span >{data[name]}</span>
      {
        name != 'noRead' ? <a onClick={goto}>详细</a> : null
      }
    </div>
  )
}

export default StatItem