import React from "react";
import { Menu, Modal } from 'antd'
import { useNavigate } from "react-router";
import { UserOutlined, UnlockOutlined, LogoutOutlined } from '@ant-design/icons';
import loginService from '../../service/login';
import service from '../../service/auth/permission';
import useCrud from '../../components/crud/useCrud';
import ChangePassword from '../../components/ChangePassword';

const { SubMenu } = Menu;

const HeaderUser = () => {

  const crud = useCrud();
  const userName = loginService.getUserName();
  const navigate = useNavigate();

  const logout = () => {
    service.clearCache();
    loginService.removeLoginInfo();
    navigate('/login');
  };

  return (
    <>
      <Menu
        mode="horizontal"
        theme="dark"
      >
        <SubMenu key="pm" icon={<UserOutlined />} title={userName}>
          <Menu.Item icon={<UnlockOutlined />} key="changepw" onClick={
            () => crud.goto('changePwd', undefined)}>修改密码</Menu.Item>
          <Menu.Item icon={<LogoutOutlined />} key="logout" onClick={logout}>登出</Menu.Item>
        </SubMenu>
      </Menu>
      {
        crud.mode ? (
          <Modal
            visible
            title="修改密码"
            onCancel={() => crud.goto(null, null)}
            footer={null}
          >
            <ChangePassword crudCtx={crud} />
          </Modal>
        ) : null
      }
    </>
  )
};

export default HeaderUser
