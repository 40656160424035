
export function findMenu(menus: any, pathname: string) {
  if (!menus) {
    return [];
  }

  for (const m1 of menus) {
    if (m1.path === pathname) {
      return [m1.id];
    }

    if (m1.children && m1.children.length) {
      for (const m2 of m1.children) {
        if (m2.path === pathname) {
          return [m1.id, m2.id];
        }

        if (m2.children && m2.children.length) {
          for (const m3 of m2.children) {
            if (m3.path === pathname) {
              return [m1.id, m2.id, m3.id];
            }
          }
        }
      }
    }
  }

  return [];
}



export function adjustMenuTree(menu: any): string | undefined {


  if (menu.redirect && Array.isArray(menu.children) && menu.children.length > 0) {
    let find = false;
    for (const m of menu.children) {
      if (m.path) {
        if (m.path === menu.redirect) {
          find = true;
        }
      } else {
        const cd = adjustMenuTree(m);
        if (cd === menu.redirect) {
          find = true;
        }
      }
    }
    if (!find) {
      menu.redirect = menu.children[0].path || menu.children[0].redirect;
    }
  }

  return menu.redirect;

}


export const minMenus = [
  {
    "id": 63,
    "title": "基础信息",
    "path": null,
    "parentId": 0,
    "parentTitle": null,
    "parentName": null,
    "ptype": 0,
    "type": 0,
    "perms": null,
    "redirect": "/base/p",
    "name": null,
    "level": null,
    "children": null,
    "orderNum": 109,
    "component": null,
    "hidden": 0,
    "icon": null,
    "createBy": null,
    "createTime": null,
    "remark": null,
    "actionEntitySet": [],
    "actions": null
  }, {
    "id": 70,
    "title": "责任人",
    "path": "/base/p",
    "parentId": 63,
    "parentTitle": null,
    "parentName": null,
    "ptype": 0,
    "type": 1,
    "perms": null,
    "redirect": null,
    "name": null,
    "level": null,
    "children": null,
    "orderNum": 111,
    "component": null,
    "hidden": 0,
    "icon": null,
    "createBy": null,
    "createTime": null,
    "remark": null,
    "actionEntitySet": [],
    "actions": null
  }
]