import { Form, FormInstance } from "antd";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useCrud, { CrudContext } from "../../../components/crud/useCrud";
import usePageTable, { PageTableContext } from "../../../components/crud/usePageTable";
import { RootState } from "../../../models/store";
import apiurl from "../../../service/apiurl";
import { paginate } from "../../../utils/utils";
import { SysUser } from "../../auth/def";


export type CRUD_TYPE = 'add' | 'editP' | 'editA' | 'allotRole';


export type TableType = '责任人信息' | '账户信息';


export const TableOption = [{ label: '责任人信息', value: '责任人信息' }, { label: '账户信息', value: '账户信息' }];


export type SearchFormType = {
  user: string;
  phone: number;
  showall: '0' | '1';
  respDuty: number[];
};

export type ICompProps = {
  pager: PageTableContext<SysUser>;
  crud: CrudContext<CRUD_TYPE>;
  isAdmin: boolean;
  searchForm: FormInstance<SearchFormType>;
  searchAdcd: (val?: string, exact?: boolean) => void;
  searchParams: (val: any) => void;
  tableView: TableType;
  setTableView: (val: TableType) => void;
  adcdRef: React.MutableRefObject<string>;
  exactRef: React.MutableRefObject<boolean>;
  exportData: (params: any) => void;
}

export type PFormShape = {
  id: number;
  user: string;
  phone: string;
  position: string;
  email: string;
  _respDuty: number[];
  respDuty?: number;
  adcd?: string;
  _adinfo: {
    adcd: string;
    adnm: string;
  }
};

export const DefVal = {
  password: 'Sh@123456',
  status: 0
}

export function usePageContext(): ICompProps {
  const crud = useCrud<CRUD_TYPE>();
  const pager = usePageTable<SysUser>((opt) => paginate(apiurl.upgramurl.user.find, opt));

  // 是否是管理员权限
  const isAdmin = useSelector((s: RootState) => s.auth.anNiu['p:op']);
  const [tableView, setTableView] = useState<TableType>('责任人信息');

  const [searchForm] = Form.useForm<SearchFormType>();

  const adcdRef = useRef<string>('');
  const exactRef = useRef<boolean>(false);

  const searchAdcd = (adcd?: string, exact?: boolean) => {
    console.log(adcd, exact);
    searchForm.resetFields();
    adcdRef.current = adcd || '';
    exactRef.current = !!exact;
    pager.search({
      search: {
        [exactRef.current ? 'adcdExact' : 'adcd']: adcdRef.current
      }
    })
  }

  const searchParams = (params: any) => {
    pager.search({
      search: {
        ...params,
        [exactRef.current ? 'adcdExact' : 'adcd']: adcdRef.current
      }
    })
  }

  const exportData = (params: { user: string, phone: string, respDuty: number }) => {

    let url = apiurl.upgramurl.user.export + "?showall=0";
    if (params.user) {
      url += `&user=${encodeURIComponent(params.user)}`;
    }
    if (params.phone) {
      url += `&phone=${encodeURIComponent(params.phone)}`;
    }
    if (params.respDuty) {
      url += `&respDuty=${params.respDuty}`;
    }
    if (adcdRef.current) {
      url += `&adcd${exactRef.current ? 'Exact' : ''}=${adcdRef.current}`;
    }

    window.open(url);
  }

  return {
    crud,
    pager,
    isAdmin,
    searchAdcd,
    searchForm,
    searchParams,
    tableView,
    setTableView,
    adcdRef,
    exactRef,
    exportData,
  }
}