import {
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { message, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import { CrudContext } from "../components/crud/useCrud";
import { SearchOption } from "../service/def";

export const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};


export const clientHeight = () => {
  return (document.getElementById('root')?.clientHeight || 1024)
    - (document.getElementsByClassName('app-header')[0]?.clientHeight || 0)
    - (document.getElementsByClassName('app-breadcrumb')[0]?.clientHeight || 0);
};

// 禁用过去
export const disabledPreviouslyDate = (current: any) => current && current < moment().subtract(1, "days");

// 禁用未来
export const disabledFutureDate = (current: any) => current && current >= moment().subtract(1, "days");



export async function paginate(url: string, params: SearchOption = {}) {
  const { search, ...pagerParams } = params;

  const reqParams: any = {
    ...search,
    ...pagerParams
  };

  try {
    const resp = await axios.post(url, reqParams);

    const data = resp.data.data;

    return { list: data.records || [], totalRow: data.total ?? 0 }
  } catch (e) {
    return { list: [], totalRow: 0 }
  }
}

export function delInPager(url: string, data: object, pageCtx: { refresh: () => void }) {
  Modal.confirm({
    title: '是否删除这条数据?',
    icon: <ExclamationCircleOutlined />,
    content: '',
    onOk: async () => {
      axios.post(url, data).then(resp => {
        if (resp.data.code === 200) {
          message.success('操作成功');
          pageCtx.refresh();
        } else {
          message.error('操作失败')
        }
      }).catch(e => {
        console.log(e);
        message.error('操作失败')
      })
    },
    onCancel() {
    },
  });
}


export function reSetPassword(url: string, data: object, pageCtx: { refresh: () => void }) {
  Modal.confirm({
    title: '是否重置密码?',
    icon: <ExclamationCircleOutlined />,
    content: '',
    onOk: async () => {
      axios.post(url, data).then(resp => {
        if (resp.data.code === 200) {
          message.success('操作成功');
          pageCtx.refresh();
        } else {
          message.error('操作失败')
        }
      }).catch(e => {
        console.log(e);
        message.error('操作失败')
      })
    },
    onCancel() {
    },
  });
}


export function restoreInPager(url: string, data: object, pageCtx: { refresh: () => void }) {
  Modal.confirm({
    title: '是否恢复这条数据?',
    icon: <ExclamationCircleOutlined />,
    content: '',
    onOk: async () => {
      axios.post(url, data).then(resp => {
        if (resp.data.code === 200) {
          message.success('操作成功');
          pageCtx.refresh();
        } else {
          message.error('操作失败')
        }
      }).catch(e => {
        message.error('操作失败')
      })
    },
    onCancel() {
    },
  });
}


let opBusy = false;

export function saveOrUpdateInPager(
  url: string,
  data: object,
  pageCtx: { refresh: () => void },
  crudCtx: CrudContext<any>,
  stateChange?: (busy: boolean) => void
) {
  if (opBusy) {
    return;
  }

  opBusy = true;
  stateChange && stateChange(true);
  axios.post(url, data).then(resp => {
    if (resp.data && resp.data.code === 200) {
      message.success(`操作成功`);
      pageCtx.refresh();
      crudCtx.goto(null, null);
    }
  }).catch(e => {
    const msg = e?.response?.data;

    if (typeof msg === 'string') {
      message.error(msg);
    } else if (typeof msg === 'object') {
      message.error(JSON.stringify(msg));
    } else {
      message.error(`操作失败`);
    }
  }).finally(() => {
    opBusy = false;
    stateChange && stateChange(false);
  })
}

export function uploadInPager(url: string, data: any, pageCtx: { refresh: () => void }, crudCtx: CrudContext<any>) {
  if (opBusy) {
    return;
  }

  const fd = new FormData();
  for (const key in data) {
    fd.set(key, data[key]);
  }

  opBusy = true;

  axios.post(url, fd, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }).then(resp => {
    if (resp.data && resp.data.code === 200) {
      message.success(`操作成功`);
      pageCtx.refresh();
      crudCtx.goto(null, null);
    }
  }).catch(e => {
    console.log(e)
    message.error(`操作失败`);
  }).finally(() => {
    opBusy = false;
  })
}


export function downloadText(content: string, mimeType: string, filename: string) {
  const a = document.createElement('a');
  const blob = new Blob([content], { type: mimeType });
  const url = URL.createObjectURL(blob);
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
}

export function toFixed(val: number, f: number) {
  if (!val) {
    return '';
  }
  return parseFloat(val.toFixed(f));
}