import { CrudContext } from "../../../components/crud/useCrud";
import { PageTableContext } from "../../../components/crud/usePageTable";
import { SysUser } from "../def";

export type CRUD_TYPE = 'add' | 'edit' | 'allotRole' | 'allotDept' | 'updateResp';

export type ICompProps = {
  pagerCtx: PageTableContext<SysUser>;
  crudCtx: CrudContext<CRUD_TYPE>
}

export type FormShape = {
  id?: number;
  name: string;
  user: string;
  password: string;
  phone: string;
  position: string;
  email: string;
  status: number;
};

export const DEF_VALUE: FormShape = {
  name: '',
  user: '',
  password: '',
  phone: '',
  position: '',
  email: '',
  status: 0
}