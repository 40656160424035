import { CrudContext } from "../../../components/crud/useCrud";
import { RequestContext } from "../../../utils/useRequest";
import { SysDept } from "../def";

export type CRUD_TYPE = 'add' | 'edit';

export type ICompProps = {
  requestCtx: RequestContext<SysDept[]>;
  crudCtx: CrudContext<CRUD_TYPE>
}

export type FormShape = {
  id?: number;
  parentId?: number;
  deptName: string;
  remark: string;
};

export const DEF_VALUE: FormShape = {
  deptName: '',
  remark: ''
}