import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Button, message } from 'antd';
import moment from 'moment';
import React, { useRef, useState } from 'react'
import apiurl from '../../../../service/apiurl';
import { httppost } from '../../../../utils/request';
import useRefresh from '../../../../utils/useRefresh';
import { UserMsg } from '../../def';



function checkTime(obj: UserMsg) {
  if (!obj.lastCheckTime) {
    return ''
  }

  const len = (((obj.checkInfo?.length || 0) + 1) / 20).toFixed(0);
  const now = moment().diff(moment(obj.lastCheckTime), 'minute');
  if (now < 60) {
    return `${now}分钟 [${len}次]`
  } else {
    return `${(now / 60).toFixed(0)}小时 [${len}次]`;
  }
}


const CheckInfo: React.FC<{
  data: any
}> = ({ data: _record }) => {
  const [record, setRecord] = useState(_record);
  const loading = useRef(false);
  const _ = useRefresh(10 * 1000);

  const setCheck = async () => {
    if (loading.current) {
      return;
    }
    loading.current = true;
    const { code, data, msg } = await httppost(apiurl.usermsg.setCheck, { id: record.id });
    loading.current = false;

    if (code === 200) {
      setRecord(data);
    } else if (msg) {
      message.error(msg);
    }
  }

  const cancelCheck = async () => {
    if (loading.current) {
      return;
    }
    loading.current = true;
    const { code, data, msg } = await httppost(apiurl.usermsg.cancelCheck, { id: record.id });
    loading.current = false;

    if (code === 200) {
      setRecord(data);
    } else if (msg) {
      message.error(msg);
    }
  }

  return (
    <div>
      {checkTime(record)}


      {
        (!record.checkInfo || record.checkInfo.length < 5 * 20) ? (
          <Button size='small' icon={<PlusCircleFilled />} onClick={setCheck} />
        ) : null
      }
      {
        record.lastCheckTime ? (<Button size='small' icon={<MinusCircleFilled />} onClick={cancelCheck} />) : null
      }


    </div>
  )
}

export default CheckInfo