import { useState, useEffect } from "react";


const useRefresh = (interval: number) => {
  const [t, setT] = useState(1);

  useEffect(() => {
    let h: number | null = null;
    if (interval) {
      h = window.setInterval(() => {
        setT(Date.now());
      }, interval);
    }
    return () => {
      if (interval && h) {
        window.clearInterval(h);
      }
    };
  }, [interval]);

  return t;
};

export default useRefresh;
