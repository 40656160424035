import JsonApi from '../../utils/jsonapi';
import CachePromise from '../../utils/cachepromise';
import { message } from 'antd';
import apiurl from '../apiurl';
import config from '../../config';

class Service extends JsonApi {

  constructor() {
    super();

    this.cacheRoleList = new CachePromise(() => this.httppost(apiurl.upgramurl.role.list), config.cacheExpireTime);
  }

  async page(params) {
    const { search, ...pageParams } = params;
    const { data, code, msg } = await this.httppost(apiurl.upgramurl.role.find, { ...search, ...pageParams }) || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return { list: [], totalRow: 0 }
    }
    data.records.forEach(d => {
      d.permissionIds = d.permissionIds.map(m => String(m));
    });
    return {
      list: data?.records ?? [],
      totalRow: data?.total ?? 0
    }
  }

  async roleList() {
    const { data, code, msg } = await this.cacheRoleList.get() || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return []
    }
    return data
  }

  async add(params) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.role.save, params) || {};
    if (code !== 200) {
      message.error(msg || '新增失败');
      return null;
    }
    message.success('新增成功');
    return true;
  }

  async edit(params) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.role.edit, params) || {};
    if (code !== 200) {
      message.error(msg || '修改失败');
      return null;
    }
    message.success('修改成功');
    return true;
  }

  async del(id) {
    const { code, msg } = await this.httppost(`${apiurl.upgramurl.role.del}`, { id }) || {};
    if (code !== 200) {
      message.error(msg || '删除失败');
      return null;
    }
    message.success('删除成功');
    return true;
  }

  async restore(id) {
    const { code, msg } = await this.httppost2(apiurl.upgramurl.role.restore, { id }) || {};
    if (code !== 200) {
      message.error(msg || '恢复失败');
      return null;
    }
    message.success('恢复成功');
    return true;
  }

  clearCache() {
    this.cacheRoleList.setNull();
  }
}

const roleService = new Service();
export default roleService;
