import JsonApi from '../../utils/jsonapi';
import { message } from 'antd';
import apiurl from '../apiurl';


class Service extends JsonApi {

    async add(params) {
        const { code, msg } = await this.httppost(apiurl.upgramurl.rolePermission.save, params) || {};
        if (code !== 200) {
            message.error(msg || '配置失败');
            return null;
        }
        message.success('配置成功');
        return true;
    }
}


const rolePermissionService = new Service();
export default rolePermissionService;
