import ProForm, {
  ProFormInstance,
  ProFormText,
} from '@ant-design/pro-form'
import React, { useRef } from 'react'
import { useNavigate } from "react-router";
import CenterForm from './crud/CenterForm'
import { DEF_INPUT_LEN } from './crud/FormLayoutProps'
import { ChangePasswordForm } from '../views/auth/def';
import { CrudContext } from './crud/useCrud';
import service from '../service/auth/user';


type IProps = {
  crudCtx: CrudContext<any>
}

const ChangePassword: React.FC<IProps> = ({ crudCtx }) => {

  const history = useNavigate();
  const formRef = useRef<ProFormInstance<ChangePasswordForm>>();

  const compareToFirstPassword = (_: any, val: string): Promise<Error | void> => {
    if (!val) {
      return Promise.reject(new Error('确认密码不能为空'));
    }
    const confirmPwd = formRef?.current?.getFieldValue('newpw');
    if (confirmPwd && val !== confirmPwd) {
      return Promise.reject(new Error('两次密码不一致!'));
    }
    return Promise.resolve();
  };

  return (
    <CenterForm>
      <ProForm
        formRef={formRef}
        onFinish={
          async (values) => {
            const ret = await service.changePwd(values);
            if (ret) {
              crudCtx.goto(null, null);
              history('/login');
            }
          }
        }
        onReset={() => formRef?.current?.setFieldsValue({
          newpw: '',
          oldpw: '',
          surePwd: ''
        })}
      >
        <ProFormText.Password
          name="oldpw"
          label="原密码"
          width={DEF_INPUT_LEN}
          placeholder="请输入原密码"
          rules={[{ required: true, message: '原密码不能为空' }]}
        />
        <ProFormText.Password
          name="newpw"
          label="新密码"
          width={DEF_INPUT_LEN}
          placeholder="请输入新密码"
          rules={[{ required: true, message: '新密码不能为空' }]}
        />
        <ProFormText.Password
          name="surePwd"
          label="确认密码"
          width={DEF_INPUT_LEN}
          placeholder="请输入确认密码"
          rules={[{ required: true, validator: compareToFirstPassword }]}
        />
      </ProForm>
    </CenterForm>
  )
};

export default ChangePassword;
