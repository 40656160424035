import {
  SearchOutlined
} from '@ant-design/icons';
import { Input, Button, Card, Space, Table, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {useMemo, useState} from 'react';
import { CrudContext } from '../../../components/crud/useCrud';
import { PageTableContext } from '../../../components/crud/usePageTable';
import { SysLoginLog} from '../def';
import { SearchOption } from "../../../service/def";
import { sysLoginLogStatus, sysLoginLogType } from "../../../service/appconsts";

const { Option } = Select;

type IProps = {
  pagerCtx: PageTableContext<SysLoginLog>;
  crudCtx: CrudContext;
}

const DataTable: React.FC<IProps> = ({ pagerCtx, crudCtx }) => {

  const [createBy, setCreateBy] = useState<string>('');
  const [type, setType] = useState<string>('');

  const columns = useMemo<ColumnsType<SysLoginLog>>(() => [
    { title: '设备品牌', key: 'brand', dataIndex: 'brand' },
    { title: '浏览器版本', key: 'browserVersion', dataIndex: 'browserVersion' },
    { title: '用户名', key: 'createBy', dataIndex: 'createBy' },
    { title: '登录时间', key: 'createTime', dataIndex: 'createTime', },
    { title: '移动设备唯一ID', key: 'deviceId', dataIndex: 'deviceId',  },
    { title: '失败信息', key: 'error', dataIndex: 'error' },
    { title: 'IP地址', key: 'ipAddr', dataIndex: 'ipAddr' },
    { title: '设备型号', key: 'model', dataIndex: 'model' },
    { title: '操作系统', key: 'operatingSystem', dataIndex: 'operatingSystem' },
    { title: '用户操作', key: 'operation', dataIndex: 'operation' },
    { title: '任务状态', key: 'status', dataIndex: 'status', render:rec=>sysLoginLogStatus[rec] },
    { title: '类型', key: 'type', dataIndex: 'type', render:rec=>sysLoginLogType[rec] },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [crudCtx]);

  const selectChange = (val:any) => {
    setType(val);
  }

  const doSearch = () => {
    const searchParams: SearchOption = {
      search: {
        createBy,
        type,
      }
    };
    pagerCtx.search(searchParams);
  };

  return (
    <>
      <Card>
        <Space size="large" wrap>
          <Space>用户名:<Input allowClear onChange={e => setCreateBy(e.target.value)}  /></Space>
          <Space>
            类型:
            <Select style={{ width: 200 }} allowClear onChange={selectChange}>
              <Option value={1}>WEB端</Option>
              <Option value={2}>APP端</Option>
            </Select>
          </Space>
          <Space>
            <Button icon={<SearchOutlined />} type="primary" onClick={doSearch}>查询</Button>
          </Space>
        </Space>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="id" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable
