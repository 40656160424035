import {
  SearchOutlined
} from '@ant-design/icons';
import ProForm, { ProFormDateRangePicker, ProFormDateTimePicker, ProFormDateTimeRangePicker, ProFormSelect } from '@ant-design/pro-form';
import { Button, Card, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useMemo } from 'react';
import { CrudContext } from '../../../components/crud/useCrud';
import usePageTable, { PageTableContext } from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { userMsgType } from '../../../service/appconsts';
import { paginate } from '../../../utils/utils';
import { UserMsg } from '../def';
import CheckInfo from './components/CheckInfo';


function noReadInfo(obj: UserMsg) {
  if (obj.status == 1) {
    return '-'
  }
  const now = moment().diff(moment(obj.createTime), 'minute');
  if (now < 60) {
    return `${now}分钟`
  } else {
    return `${(now / 60).toFixed(0)}小时`;
  }
}

const DataTable: React.FC<{
  crudCtx: CrudContext
}> = ({ crudCtx }) => {

  const record = crudCtx.record || {}

  const initialSearch = useMemo<any>(() => {
    const params: any = {
      stm: record.stm || moment().format('YYYY-MM-DD 08:00:00'),
      etm: moment().format('YYYY-MM-DD 23:59:59'),
    }
    if (record.noReadTime !== '0') {
      params.status = '0';
      params.noReadTime = parseInt(record.noReadTime);
    }
    return params;
  }, []);

  const pagerCtx = usePageTable<UserMsg>((opt) => paginate(apiurl.usermsg.find, opt), {
    search: initialSearch,
    sortField: 'create_time',
    sortOrder: 'desc'
  });


  const columns = useMemo<ColumnsType<UserMsg>>(() => [
    { title: '类型', key: 'type', dataIndex: 'type', render: val => userMsgType[val] || '-' },
    { title: '用户', key: 'userName', dataIndex: 'sysUser', render: sysUser => sysUser?.user },
    { title: '电话', key: 'phone', dataIndex: 'sysUser', render: sysUser => sysUser?.phone },
    { title: '职务', key: 'position', dataIndex: 'sysUser', render: sysUser => sysUser?.position },
    { title: '职责', key: 'duty', dataIndex: 'sysUser', render: sysUser => sysUser?.duty },
    { title: '内容', key: 'content', dataIndex: 'content', ellipsis: true },
    { title: '未读时间', key: 'createTime', render: noReadInfo },
    { title: '检查时间', key: 'checkInfo', render: rec => <CheckInfo data={rec} /> },
  ], []);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={
            async (data) => {
              const params: any = {
                stm: data.time[0],
                etm: data.time[1],
              };
              if (data.noReadTime !== '0') {
                params.status = '0';
                params.noReadTime = parseInt(data.noReadTime);
              }
              pagerCtx.search({
                search: params
              })
            }
          }
          submitter={false}
          initialValues={{
            time: [
              moment(pagerCtx.initialSearch?.stm),
              moment(pagerCtx.initialSearch?.etm)
            ],
            noReadTime: `${initialSearch.noReadTime ?? '0'}`,
          }}
        >
          <ProFormDateTimeRangePicker
            name="time"
            required
            label="时间"
          />
          <ProFormSelect
            name="noReadTime"
            label="是否未读"
            width="sm"
            options={[
              { value: '0', label: '全部' },
              { value: `${5 * 60 * 1000}`, label: '5分钟内未读' },
              { value: `${10 * 60 * 1000}`, label: '10分钟内未读' },
              { value: `${20 * 60 * 1000}`, label: '20分钟内未读' },
              { value: `${30 * 60 * 1000}`, label: '30分钟内未读' },
              { value: `${40 * 60 * 1000}`, label: '40分钟内未读' },
              { value: `${50 * 60 * 1000}`, label: '50分钟内未读' },
              { value: `${60 * 60 * 1000}`, label: '1小时内未读' },
            ]}
          />
          <Space>
            <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
            <Button onClick={() => crudCtx.goto(null, null)}>返回汇总</Button>
          </Space>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="id" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable
