import {
  FolderAddOutlined, PlusOutlined, SearchOutlined
} from '@ant-design/icons';
import ProForm, { ProFormCheckbox, ProFormText } from '@ant-design/pro-form';
import { Button, Card, Divider, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import OpButton, { DelOpButton, EditOpButton, RestoreOpButton } from '../../../components/crud/OpButton';
import apiurl from '../../../service/apiurl';
import { delInPager, restoreInPager } from '../../../utils/utils';
import { SysDept } from '../def';
import { DEF_VALUE, ICompProps } from './common';

const DataTable: React.FC<ICompProps> = ({ requestCtx, crudCtx }) => {

  const columns = useMemo<ColumnsType<SysDept>>(() => [
    { title: '部门名称', key: 'deptName', dataIndex: 'deptName' },
    { title: '部门描述', key: 'remark', dataIndex: 'remark' },
    { title: '创建时间', key: 'createTime', dataIndex: 'createTime' },
    {
      title: '操作', key: '_', render: rec => (
        <Space split={<Divider type="vertical" />}>
          {
            rec?.del === 0 ? (
              <>
                <OpButton icon={<FolderAddOutlined />} tooltip="添加子部门" onClick={() => crudCtx.goto('add', { parentId: rec.id, parentName: rec.deptName })} />
                <EditOpButton onClick={() => crudCtx.goto('edit', rec)} />
                <DelOpButton onClick={() => delInPager(apiurl.upgramurl.dept.del, { id: rec.id }, requestCtx)} />
              </>
            ) : (
              <RestoreOpButton onClick={() => restoreInPager(apiurl.upgramurl.dept.restore, { id: rec.id }, requestCtx)} />
            )
          }
        </Space>
      )
    }
  ], [crudCtx]);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={
            async (data) => {
              requestCtx.refresh({
                deptName: data.deptName,
                showall: data.showall ? '1' : '0',
              })
            }
          }
          submitter={false}
        >
          <ProFormText label="部门名称" name="deptName" />
          <ProFormCheckbox label="全部" name="showall" />
          <Space>
            <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
            <Button icon={<PlusOutlined />} onClick={() => crudCtx.goto('add', { ...DEF_VALUE })}>新建</Button>
          </Space>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={requestCtx.data}
          loading={requestCtx.loading}
          size='small'
          bordered
        />
      </Card>
    </>
  )
};

export default DataTable
