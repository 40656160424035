import { FolderAddOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Radio, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import OpButton, { DelOpButton, EditOpButton, RestoreOpButton, ResPasswordOpButton } from '../../../components/crud/OpButton';
import apiurl from '../../../service/apiurl';
import { pType, pTypeStr } from '../../../service/appconsts';
import { renYYYYMMDD } from '../../../utils/renutil';
import { delInPager, restoreInPager, reSetPassword } from '../../../utils/utils';
import { SysUser, SysUserDept } from '../../auth/def';
import { ICompProps, TableOption } from './_';

const DataTable: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {

  const { pager, crud, isAdmin } = ctx;

  const columns = useMemo<ColumnsType<SysUser>>(() => {
    const ret: ColumnsType<SysUser> = [
      { title: '姓名', key: 'user', dataIndex: 'user' },
      { title: '归属行政区划', key: 'adnm', dataIndex: 'adnm' },
      // { title: '登录账户', key: 'name', dataIndex: 'name' },
      {
        title: '联系电话', key: 'phone', render: (rec: SysUser) => (
          !isAdmin && rec.respDuty && (rec.respDuty & pType.党政一把手) === 0 ? null : rec.phone
        )
      },
      {
        title: '责任人类型', key: 'respDuty', dataIndex: 'respDuty', render: (val: number) => (

          Object.keys(pTypeStr).map(o => (val & parseInt(o)) ? pTypeStr[o as any] : null).filter(Boolean).join(', ')

        )
      },
      { title: '职务', key: 'position', dataIndex: 'position' },
      { title: '职责', key: 'duty', dataIndex: 'duty' },
    ];
    if (isAdmin) {
      ret.push(
        {
          title: '操作', key: "op", align: 'center', render: (rec: SysUser) => (
            <Space>
              <EditOpButton onClick={() => ctx.crud.goto('editP', rec)} />
            </Space>
          )
        }
      )
    }
    return ret;
  }, [isAdmin]);


  const columns2 = useMemo<ColumnsType<SysUser>>(() => {
    const ret: ColumnsType<SysUser> = [
      { title: '姓名', key: 'user', dataIndex: 'user' },
      { title: '登录名', key: 'name', dataIndex: 'name' },
      { title: '归属行政区划', key: 'adnm', dataIndex: 'adnm' },
      { title: '用户权限', key: 'role', render: rec => rec.sysUserRoles?.map((o: any) => o.roleName).join(', ') },
    ];

    if (isAdmin) {
      ret.push({
        title: '操作', key: '_', align: 'center', render: rec => (
          <>
            {
              rec?.del === 0 ? (
                <Space split={<Divider type="vertical" />}>
                  <OpButton tooltip='分配角色' icon={<FolderAddOutlined />} onClick={
                    () => crud.goto('allotRole',
                      { id: rec.id, roleIds: rec.roleIds.map((m: number) => String(m)) })} />
                  <EditOpButton onClick={() => crud.goto('editA', rec)} />
                  <ResPasswordOpButton onClick={() => reSetPassword(apiurl.upgramurl.user.reset, { id: rec.id }, pager)} />
                  <DelOpButton onClick={() => delInPager(apiurl.upgramurl.user.del, { id: rec.id }, pager)} />
                  {/*  */}
                </Space>
              ) : null
            }
            {
              rec?.del === 1 && (
                <RestoreOpButton onClick={() => restoreInPager(apiurl.upgramurl.user.restore, { id: rec.id }, pager)} />
              )
            }
          </>
        )
      })
    }

    return ret;
  }, [isAdmin])

  return (
    <Card extra={
      <Radio.Group
        optionType='button'
        buttonStyle='solid'
        options={TableOption}
        value={ctx.tableView}
        onChange={e => ctx.setTableView(e.target.value)}
      />
    }>
      <Table
        key={ctx.tableView}
        rowKey="id"
        columns={ctx.tableView === '责任人信息' ? columns : columns2}
        {...pager.tableProps}
      />
    </Card>
  )
};

export default DataTable;
