import { Models } from "@rematch/core"
import { auth } from "./auth";
import { usergps } from "./base/usergps";

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  usergps: typeof usergps
}

export const models: RootModel = {
  auth,
  usergps
};
