import { httppost } from "../../utils/request";
import { message } from 'antd';
import apiurl from '../apiurl';

export async function page(params = {}) {
  const { search, ...pageParams } = params;
  const { data, code, msg } = await httppost(apiurl.upgramurl.sysLoginLog.page, { ...search, ...pageParams }) || {};
  if (code !== 200) {
    message.error(msg || '请求失败');
    return { list: [], totalRow: 0 };
  }
  return { list: data.records, totalRow: data.total };
}


