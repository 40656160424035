import React from 'react';
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { paginate } from '../../../utils/utils';
import { Adinfo } from '../def';
import DataTable from './DataTable';

const Page: React.FC = () => {

  const pager = usePageTable<Adinfo>(async (params) => paginate(apiurl.base.adinfo.find, params));

  return (
    <div className="content-root">
      <DataTable pagerCtx={pager} />
    </div>
  )
};

export default Page
