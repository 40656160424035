import { DeleteOutlined, EditOutlined, UndoOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd'
import React from 'react'


type IProps = {
  icon?: React.ReactNode;
  danger?: boolean;
  text?: string;
  tooltip?: string;
  onClick?: () => void;
};

const OpButton: React.FC<IProps> = ({ tooltip, icon, danger, text, onClick }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Button size="small" type="link" icon={icon} danger={danger} onClick={onClick}>{text}</Button>
      </Tooltip>
    )
  }
  return (
    <Button size="small" type="link" icon={icon} danger={danger} onClick={onClick}>{text}</Button>
  )
}

export default OpButton;

type ITypedProps = {
  onClick?: () => void;
}

export const DelOpButton: React.FC<ITypedProps> = ({ onClick }) =>
  <OpButton tooltip='删除' icon={<DeleteOutlined />} onClick={onClick} danger />

export const EditOpButton: React.FC<ITypedProps> = ({ onClick }) =>
  <OpButton tooltip='编辑' icon={<EditOutlined />} onClick={onClick} />

export const RestoreOpButton: React.FC<ITypedProps> = ({ onClick }) =>
  <OpButton tooltip='恢复' icon={<UndoOutlined />} onClick={onClick} />

  export const ResPasswordOpButton: React.FC<ITypedProps> = ({ onClick }) =>
  <OpButton tooltip='重置密码' icon={<SyncOutlined />} onClick={onClick} />

