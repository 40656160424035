import ProForm, { ProFormInstance, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import React, { useRef } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import { CrudContext } from "../../../components/crud/useCrud";
import { PageTableContext } from "../../../components/crud/usePageTable";
import apiurl from '../../../service/apiurl';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { SysRole } from "../def";
import { DEF_VALUE, FormShape } from './common';


type IProps = {
  pagerCtx: PageTableContext<SysRole>;
  crudCtx: CrudContext
}

const DataForm: React.FC<IProps> = ({ pagerCtx, crudCtx }) => {
  const formRef = useRef<ProFormInstance<FormShape>>();

  const record = crudCtx.record || DEF_VALUE;

  const submit = async (values: FormShape) => {
    const url = crudCtx.mode === 'add' ? apiurl.upgramurl.role.save : apiurl.upgramurl.role.edit;
    saveOrUpdateInPager(url, values, pagerCtx, crudCtx);
  }

  return record ? (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={submit}
        onReset={() => formRef?.current?.setFieldsValue(DEF_VALUE)}
      >
        {
          crudCtx.mode === 'edit' && (<ProFormText name="id" hidden />)
        }
        <ProFormText
          name="roleName"
          label="角色名"
          placeholder="请输入角色名"
          width={DEF_INPUT_LEN}
          rules={[{ required: true, message: '角色名称不能为空' }]}
          fieldProps={{ allowClear: true }}
        />
        <ProFormTextArea
          name="remark"
          label="角色描述"
          placeholder="请输入角色描述"
          width={DEF_INPUT_LEN}
          fieldProps={{
            allowClear: true,
            autoSize: { minRows: 2, maxRows: 5 }
          }}
        />
      </ProForm>
    </CenterForm>
  ) : null;
};

export default DataForm;
