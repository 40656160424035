import moment from 'moment';

const class2type = {};
const { toString } = class2type;
'Boolean Number String Function Array Date RegExp Object Error'.split(' ').forEach((name) => {
  class2type[`[object ${name}]`] = name.toLowerCase();
});
/**
 * 对象类型
 * @param {object} obj
 */
export function objType(obj) {
  if (obj == null) {
    return `${obj}`;
  }
  return typeof obj === 'object' || typeof obj === 'function' ? class2type[toString.call(obj)] || 'object' : typeof obj;
}
/**
 * 是否数组
 * @param {object} obj 需要判断的对象
 * @return {boolean} 返回 true 或 false
 */
export function isArray(obj) {
  return objType(obj) === 'array';
}
/**
 * 将简单结构数据转换成树状结构
 * @param {object} setting 设置节点的 idKey\pIdKey\children
 * @param {array} sNodes 数据数组
 * @return {array} 树状解构数据
 */
export function transformToTreeFormat(setting, sNodes) {
  setting = setting || {};
  let i;
  let l;
  const key = setting.idKey || 'id'; // 当前节点的唯一key名
  const parentKey = setting.pIdKey || 'pid'; // 指向的父节点唯一key名
  const childKey = setting.children || 'children'; // 子元素的key名

  if (!key || key === '' || !sNodes) return [];

  if (isArray(sNodes)) {
    const r = [];
    const tmpMap = [];
    for (i = 0, l = sNodes.length; i < l; i += 1) {
      tmpMap[sNodes[i][key]] = sNodes[i];
    }
    for (i = 0, l = sNodes.length; i < l; i += 1) {
      if (tmpMap[sNodes[i][parentKey]] && sNodes[i][key] !== sNodes[i][parentKey]) {
        if (!tmpMap[sNodes[i][parentKey]][childKey]) {
          tmpMap[sNodes[i][parentKey]][childKey] = [];
        }
        tmpMap[sNodes[i][parentKey]][childKey].push(sNodes[i]);
      } else {
        r.push(sNodes[i]);
      }
    }
    return r;
  }

  return [sNodes];
}

/**
 * 获取上级adcd
 * @param {string} adcd 15位
 * @return {string} 上级行政区划 adcd 15 位
 */
export function getAdcdPid(adcd) {
  if (!adcd || adcd.length !== 15) {
    return '';
  }

  if (adcd === '429021000000000') { // 神农架
    return '429021000000000';
  } else if (/00000000000$/.test(adcd)) { // 市
    return '420000000000000';
  } else if (/000000000$/.test(adcd)) { // 县\区\县级市
    return `${adcd.substring(0, 4)}00000000000`;
  } else if (/000000$/.test(adcd)) { // 乡\镇
    return `${adcd.substring(0, 6)}000000000`;
  } else if (/000$/.test(adcd)) { // 行政村
    return `${adcd.substring(0, 9)}000000`;
  }

  return `${adcd.substring(0, 12)}000`; // 自然村
}

/**
 * int 属性值排序
 * @param {object} o1
 * @param {object} o2
 * @param {string} key
 */
export function orderByInt(o1, o2, key) {
  const v1 = o1[key] || 0;
  const v2 = o2[key] || 0;

  if (parseInt(v1, 10) >= parseInt(v2, 10)) {
    return 1;
  }

  return -1;
}
/**
 * 检查用户是否登陆
 */
export function checkUL() {
  return true;
  // const token = cookies.get('token');
  // const uid = cookies.get('uid');

  // return token != null && uid != null;
}


export function isEmptyObject(e) {
  if (e === undefined || e === null) {
    return true;
  }
  for (const t in e) {
    return false;
  }

  return true;
}

export function strIsEmpty(v) {
  return v === undefined || v == null || v === '';
}

export function complexUrlParams(resultObj, key, obj) {
  if (!resultObj) {
    resultObj = {};
  }
  if (typeof obj !== 'object') {
    resultObj[key] = obj;
    return resultObj;
  }

  for (const subkey in obj) {
    complexUrlParams(resultObj, `${key}[${subkey}]`, obj[subkey]);
  }
  return resultObj;
}

export function arr2map(arrData) {
  if (!Array.isArray(arrData)) {
    return null;
  }
  const ret = {};
  for (const str of arrData) {
    ret[str] = true;
  }
  return ret;
}

export function map2arr(mapData) {
  if (!mapData) {
    return null;
  }

  const ret = [];
  for (const key in mapData) {
    if (mapData[key]) {
      ret.push(key);
    }
  }
  return ret;
}

export function isDebug() {
  return window.location.host.indexOf('dev') > 0 || window.location.host.indexOf('localhost') >= 0;
}


export function normalizeSearchTmRange(tm, type) {
  if (!tm || !tm[0] || !tm[1]) {
    return undefined;
  }

  if (tm[0] instanceof Date) {
    tm[0] = moment(tm[0]);
  }
  if (tm[1] instanceof Date) {
    tm[1] = moment(tm[1]);
  }

  if (type === 'd') {
    return [
      tm[0].format('YYYY-MM-DD 00:00:00'),
      tm[1].format('YYYY-MM-DD 00:00:00'),
    ];
  }
  if (type === 'm') {
    return [
      tm[0].format('YYYY-MM-01 00:00:00'),
      tm[1].format('YYYY-MM-01 00:00:00'),
    ];
  }
  if (type === 't') {
    return [
      tm[0].format('YYYY-MM-01 00:00:00'),
      tm[1].format('YYYY-MM-21 00:00:00'),
    ];
  }
  if (type === 'y') {
    return [
      tm[0].format('YYYY-01-01 00:00:00'),
      tm[1].format('YYYY-01-01 00:00:00'),
    ];
  }
  if (type === 'h') {
    return [
      tm[0].format('YYYY-MM-DD HH:00:00'),
      tm[1].format('YYYY-MM-DD HH:00:00'),
    ];
  }

  return [
    tm[0].format('YYYY-MM-DD HH:mm:00'),
    tm[1].format('YYYY-MM-DD HH:mm:00'),
  ];
}

export function normalizeRzMinMax_Dangyang(min, max) {
  let max50cm = Math.ceil(max * 2);
  let min50cm = Math.floor(min * 2);
  let av50cm = Math.ceil(max + min);

  if (av50cm < 3) {
    av50cm = 3;
  }
  if (min50cm < 0) {
    min50cm = 0
  }

  if (max50cm < av50cm + 3) {
    max50cm = av50cm + 3;
  }
  if (min50cm > av50cm - 3) {
    min50cm = av50cm - 3;
  }

  return {
    min: min50cm / 2,
    max: max50cm / 2,
  }
}

export const MomentTypeFromTmType = {
  h: 'hour',
  m: 'month',
  d: 'day',
}

export function parseGeoJSONFeature(data) {
  if (typeof data.lgtd === 'number' && typeof data.lttd === 'number') {
    return {
      type: "Feature",
      properties: data,
      geometry: {
        type: "Point",
        coordinates: [data.lgtd, data.lttd]
      }
    };
  } else if (data.geometry) {
    let geometryObj = data.geometry;
    if (typeof geometryObj === 'string') {
      try {
        geometryObj = JSON.parse(geometryObj);
      } catch (e) {
        geometryObj = null;
      }
    }
    if (geometryObj?.type) {
      return {
        type: "Feature",
        properties: data,
        geometry: geometryObj
      }
    }
  }
  return null;
}

export function parseGeoJSON(data) {
  const ret = {
    type: "FeatureCollection",
    name: "drpreal",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    features: []
  };

  if (Array.isArray(data)) {
    ret.features = data.map(parseGeoJSONFeature).filter(Boolean);
  }

  return ret;
}

export function wait(tm) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, tm || 0);
  });
}


export function strNumber(val, def) {
  if (typeof val === "number") {
    return val;
  }

  return def;
}

export function KrM100Render(val) {
  if (typeof val !== "number") {
    return '-';
  }

  return (val * 100).toFixed(2);
}


/**
 * 深度优先遍历树
 * 一个递归方法
 * @params tree: 要转换的树结构数据
 * @params list: 保存结果的列表结构数据，初始传list = []
 * @params parentId: 当前遍历节点的父级节点id，初始为null(因为根节点无parentId)
 **/
function toListDF(tree, list) {
    for (let node of tree) { //遍历最上层
        list.push({
            id: node.id,
            key: node.key,
            title: node.title,
            type: node?.type,
            parentId: node?.parentId
        });
        //如果有子结点,再遍历子结点
        if (node.children.length !== 0) {
            toListDF(node.children, list)  //递归
        }
    }
}

/**
 * 树转list
 */
export function treeToList(tree){
    const list = [];  // 结果lsit
    for(let node of tree){
        if (node?.children?.length !== 0) {  //遍历树的第一层,只有一个根结点
            toListDF(node.children, list);  //遍历子树,并加入到list中.
        }
    }
    return list;
}

/**
 * 将时间戳转换成年月日
 */
export function timeChange(val){
  let time = moment(val).format()

  let d = new Date(time);

  let batchDate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();

  return batchDate;
}


function childNodesDeep(nodes, arr) {
    if (nodes)
        nodes.forEach((ele) => {
            arr.push(ele.key);
            if (ele.children) {
                childNodesDeep(ele.children, arr);
            }
        });
  }

/**
 * 获取父子节点下所有子节点的key
 * @param nodes
 * @param key
 * @param arr
 * @returns {Array}
 */
export function getChildKysByParentKey(nodes, key, arr) {
    for (let el of nodes) {
        if (el.key === key) {
            arr.push(el.key);
            if (el.children) {
                childNodesDeep(el.children, arr);
            }
        } else if (el.children) {
            getChildKysByParentKey(el.children, key, arr);
        }
    }
    return arr;
}
