import { createModel } from "@rematch/core";
import axios from 'axios';
import { RootModel } from "..";
import apiurl from "../../service/apiurl";
import { LoginUser } from "../../service/def";
import loginService from "../../service/login";
import * as tools from '../../utils/tools';
import { AuthState } from "../def";
import { adjustMenuTree, minMenus } from "./menu";



export const auth = createModel<RootModel>()({
  state: {
    user: loginService.getUser(),
    menu: [],
    anNiu: {},
  } as AuthState,

  reducers: {
    setUser(state, user: LoginUser): AuthState {
      return { ...state, user };
    },

    setMenu(state, menu: any): AuthState {
      return { ...state, menu };
    },

    setAnNiu(state, anNiu: any): AuthState {
      return { ...state, anNiu };
    },

    setState(state, obj: any) {
      return { ...state, ...obj };
    }
  },

  effects: {
    async login(payload: any): Promise<boolean> {
      const result = await loginService.auth(payload);

      if (result.code === 200 && result.data) {
        this.setUser(result.data)

        return true;
      }

      return false;
    },

    logout() {
      loginService.removeLoginInfo();

      this.setUser(null);
    },

    async loadMenu() {

      const resp = await axios.get(apiurl.upgramurl.permission.findPermissions);
      // debugger
      let menu = resp?.data?.data;

      if (!menu || menu.length === 0) {
        menu = minMenus;
      }

      menu.sort((o1: any, o2: any) => tools.orderByInt(o1, o2, 'orderNum'));

      const menuTree = tools.transformToTreeFormat({ pIdKey: 'parentId' }, menu);
      // 防止目录上的redirect没有对应的子菜单
      for (const o of menuTree) {
        adjustMenuTree(o);
      }

      this.setMenu(menuTree);

      const strAnNiuResp = await axios.get(apiurl.upgramurl.permission.findAnNiu);
      const strAnNiu: string = strAnNiuResp?.data?.data || '';

      if (strAnNiu) {
        const anNiuList = strAnNiu.split(',').filter(Boolean);
        const anNiuMap: { [key: string]: boolean } = {};
        for (const s of anNiuList) {
          anNiuMap[s] = true;
        }

        this.setAnNiu(anNiuMap);
      }
    }
  }
});
