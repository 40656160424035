import React, { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import { SysRole, SysRolePermission } from '../def';
import DataForm from './DataForm';
import DataTable from './DataTable';
import StrictlyTreeSelect from '../../../components/common/tree/StrictlyTreeSelect';
import roleService from "../../../service/auth/role";
import rolePermissionService from '../../../service/auth/rolePermission';
import { SearchOption, TreeNode } from "../../../service/def";
import { getPermissionTreeData } from "../../../utils/sysutils";
import { paginate } from '../../../utils/utils';
import apiurl from '../../../service/apiurl';
import { CRUD_TYPE } from './common';
import PermissionForm from './PermissionForm';

const RolePage: React.FC = () => {

  const crud = useCrud<CRUD_TYPE>();
  const pager = usePageTable<SysRole>((opt) => paginate(apiurl.upgramurl.role.find, opt));

  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<React.Key[]>([]);
  let [refreshKey, setRefreshKey] = useState<number>(0);

  useEffect(() => {
    getPermissionTreeData().then(data => {
      setTreeData(data);
    });
    return () => { setRefreshKey(0); }
  }, [refreshKey]);

  const txt = crud.mode === 'add' ? '新增角色' : crud.mode === 'edit' ? '修改角色' : '配置权限';

  const onOk = async () => {
    if (ids.length === 0) {
      message.info('权限未变化');
      return;
    }
    const id: number = crud.record.id;
    const params: SysRolePermission[] = ids.map(d => ({ roleId: id, permissionId: Number(d) }))
      .filter(f => f.permissionId !== 0); // 过滤到根目录
    setLoading(true);
    const ret = await rolePermissionService.add(params);
    setLoading(false);
    if (ret) {
      crud.goto(null, null);
      pager.refresh();
      setRefreshKey(refreshKey++);
    }
  };


  return (
    <div className="content-root">
      {
        crud.mode === 'add' || crud.mode === 'edit' ? (
          <Modal
            visible
            footer={null}
            style={{ top: 20 }}
            title={crud.mode === 'add' ? '新增角色' : '修改角色'}
            onCancel={() => crud.goto(null, null)}
          >
            <DataForm crudCtx={crud} pagerCtx={pager} />
          </Modal>
        ) : null
      }
      {
        crud.mode === 'auth' ? (<PermissionForm crudCtx={crud} pagerCtx={pager} />) : null
      }
      <DataTable crudCtx={crud} pagerCtx={pager} />
    </div>
  )
};

export default RolePage
