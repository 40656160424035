import ProForm, {
  ProFormInstance, ProFormRadio, ProFormText
} from '@ant-design/pro-form';
import React, { useRef } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import apiurl from '../../../service/apiurl';
import { userStatus } from "../../../service/appconsts";
import { getLabelValueByObj } from '../../../utils/sysutils';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { phoneValidator } from "../../../utils/validator";
import { Form } from 'antd';
import { DEF_VALUE, FormShape, ICompProps } from './common';
import AdcdTreeSelect from '../../../components/common/tree/AdcdTreeSelect';

const userStatusOptions = getLabelValueByObj(userStatus);


const DataForm: React.FC<ICompProps> = ({ pagerCtx, crudCtx }) => {
  const formRef = useRef<ProFormInstance<FormShape>>();
  const record = crudCtx.record;

  const submit = async (values: FormShape) => {
    const url = crudCtx.mode === 'add' ? apiurl.upgramurl.user.save : apiurl.upgramurl.user.edit;
    saveOrUpdateInPager(url, values, pagerCtx, crudCtx);
  }

  const reset = () => formRef?.current?.setFieldsValue(DEF_VALUE)

  return record ? (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={submit}
        onReset={reset}
      >
        {
          crudCtx.mode === 'edit' && (<ProFormText name="id" hidden />)
        }
        <ProFormText
          name="name"
          label="登陆账号"
          width={DEF_INPUT_LEN}
          placeholder="请登陆输入账号"
          rules={[{ required: true, message: '账号不能为空' }]}
        />
        {
          crudCtx.mode === 'add' && (
            <ProFormText.Password
              name="password"
              label="密码"
              width={DEF_INPUT_LEN}
              placeholder="请输入密码"
              rules={[{ required: true, message: '密码不能为空' }]}
            />
          )
        }
        <ProFormText
          name="user"
          label="用户姓名"
          width={DEF_INPUT_LEN}
          placeholder="请输入用户姓名"
          rules={[{ required: true, message: '用户姓名不能为空' }]}
        />
        <ProFormText
          name="phone"
          label="联系电话"
          width={DEF_INPUT_LEN}
          rules={[{ required: true, validator: phoneValidator }]}
        />
        <ProFormText
          name="position"
          label="职务"
          width={DEF_INPUT_LEN}
        />
        <ProFormText
          name="duty"
          label="职责"
          width={DEF_INPUT_LEN}
        />
        <ProFormText
          name="org"
          label="在职单位"
          width={DEF_INPUT_LEN}
        />

        <Form.Item
          name="adcd"
          label="归属行政区划"
          rules={[{ required: true }]}
        >
          <AdcdTreeSelect allowClear style={{ width: DEF_INPUT_LEN }} initadnm={record.adnm} />
        </Form.Item>
        <ProFormRadio.Group
          label="是否启用"
          name="status"
          width={DEF_INPUT_LEN}
          options={userStatusOptions}
        />
      </ProForm>
    </CenterForm>
  ) : null;
};

export default DataForm;