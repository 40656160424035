import * as tools from './tools';
import loginService from '../service/login';


function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function getToken() {
  return sessionStorage.getItem('token') || null;
}

function request(url, options) {
  const opt = { ...options };
  opt.headers = opt.headers || {};
  opt.headers.Accept = 'application/json';
  opt.headers.token = getToken();
  return fetch(url, opt)
    .then(checkStatus)
    .then(response => response.json())
    .then(data => ({ data })) // { data: 接口实际返回的数据 }
    .catch(err => ({ err })); // { err: 错误信息 }
}

export default class JsonApi {

  async send(url, options) {
    try {
      const res = await request(url, options);
      if (!('data' in res) && 'err' in res) {
        // message.error(getErrCode(res.err.message));
        return null;
      }
      const { code, msg } = res.data;
      if (code !== 200 && typeof msg === 'string') {
        //message.error(res.data.msg);
      }
      if (code === 401) {
        loginService.removeLoginInfo();
        window.location.href = '/#/login';
      }

      return res.data;
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  httpget(url, data = {}, browserCache = false) {
    const params = [];
    for (const k in data) {
      const v = data[k];
      if (tools.strIsEmpty(v)) {
        continue;
      }

      params.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
    if (!browserCache && !data._) {
      params.push(`_=${Date.now()}`);
    }
    const strparams = params.join('&');
    if (params.length > 0) {
      if (url.indexOf('?') > 0) {
        url += `&${strparams}`;
      } else {
        url += `?${strparams}`;
      }
    }
    const options = {
      method: 'GET',
    };

    return this.send(url, options);
  }


  /**
   * 不带文件的psot提交
   * @param {string} url 接口地址
   * @param {object} data 要提交的对象
   */
  httppost(url, data = {}) {
    let body = {};
    if (Array.isArray(data)) {
      body = JSON.stringify(data);
    } else {
      const _data = {}; // 去掉空的属性
      for (const k in data) {
        const v = data[k];
        if (!tools.strIsEmpty(v)) {
          _data[k] = v;
        }
      }
      body = JSON.stringify(_data);
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    };

    return this.send(url, options);
  }

  httppost2(url, data = {}) {
    const params = [];
    // 去掉空的属性
    for (const k in data) {
      const v = data[k];
      if (tools.strIsEmpty(v)) {
        delete [k];
        continue;
      }
      params.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
    const strparams = params.join('&');
    if (params.length > 0) {
      if (url.indexOf('?') > 0) {
        url += `&${strparams}`;
      } else {
        url += `?${strparams}`;
      }
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    };

    return this.send(url, options);
  }

  /**
   * 带文件的psot提交
   * @param url 接口地址
   * @param options: { body: formData } 要提交的form对象
   */
  httpPostFile(url, options = {}) {
    options.method = 'POST';
    options.processData = false;
    options.headers = options.headers || {};

    if (options.headers instanceof Headers) {
      options.headers.append('enctype', 'multipart/form-data');
    } else {
      options.headers.enctype = 'multipart/form-data';
    }

    return this.send(url, options);
  }

  /**
   * 附件下载
   * @param url
   * @param params
   */
  download(url, params) {
    let parts = [];
    if (typeof params !== 'string') {
      for (const key in params) {
        const value = params[key];
        if (tools.strIsEmpty(value)) {
          continue;
        }
        parts.push(`${key}=${encodeURIComponent(`${value}`)}`);
      }
    } else {
      parts.push(params);
    }
    window.open(`${url}?${parts.join('&')}&token=${getToken()}`);
  }

}




