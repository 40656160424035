import ProForm, {
  ProFormInstance, ProFormRadio, ProFormText, ProFormTextArea
} from '@ant-design/pro-form';
import React, { useRef, useState } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import apiurl from '../../../service/apiurl';
import { saveOrUpdateInPager, uploadInPager } from '../../../utils/utils';
import { Button, Form, Upload } from 'antd';
import { FormShape, ICompProps } from './_';
import AdcdTreeSelect from '../../../components/common/tree/AdcdTreeSelect';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';


const FileUpload: React.FC<{
  value?: any;
  onChange?: (val: any) => void;
}> = ({ value, onChange }) => {
  return (
    <Upload
      accept="application/pdf,.doc,.docx,.ppt,.pptx"
      multiple={false}
      fileList={[value].filter(Boolean)}
      beforeUpload={file => {
        onChange && onChange(file);
        return false;
      }}
      onRemove={() => {
        onChange && onChange(undefined);
      }}
      maxCount={1}
      onChange={(info) => {
        console.log(info);
      }}
    >
      <Button icon={<UploadOutlined />}>选择文件</Button>
    </Upload>
  );
}


const DataForm: React.FC<ICompProps> = ({ pagerCtx, crudCtx }) => {
  const formRef = useRef<ProFormInstance<FormShape>>();
  const record = crudCtx.record;

  const submit = async (values: FormShape) => {
    uploadInPager(apiurl.trainres.upload, values, pagerCtx, crudCtx);
  }

  const reset = () => formRef?.current?.setFieldsValue({})

  return record ? (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={submit}
        onReset={reset}
      >
        <ProFormText
          name="name"
          label="文件名"
          width={DEF_INPUT_LEN}
          placeholder="请输入文件名"
          rules={[{ required: true, message: '文件名不能为空' }]}
        />
        <Form.Item
          name="adcd"
          label="行政区划"
        >
          <AdcdTreeSelect allowClear style={{ width: DEF_INPUT_LEN }} initadnm={record.adnm} />
        </Form.Item>
        <Form.Item
          name="file"
          label="行政区划"
          rules={[{ required: true, message: '请选择上传文件' }]}
        >
          <FileUpload />
        </Form.Item>

        <ProFormTextArea
          name="remark"
          label="备注"
          width={DEF_INPUT_LEN}
          placeholder="请输入备注"
        />
      </ProForm>
    </CenterForm>
  ) : null;
};

export default DataForm;