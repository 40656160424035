import React from 'react'

const CenterForm: React.FC = ({ children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: 520 }}>
        {children}
      </div>
    </div>
  )
};

export default CenterForm
