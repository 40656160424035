import { Checkbox, Row, Col } from 'antd';
import React, { useState } from 'react';
import { labelValueItem } from "../../../utils/sysutils";


const RoleSelect: React.FC<{
  data: labelValueItem[];
  value: string[];
  change: (value: string[]) => void;
}> = ({ value, change, data }) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(value);


  const onChange = (selectedKeysValue: any) => {
      setSelectedKeys(selectedKeysValue);
      change(selectedKeysValue);
  };


  return data.length ? (
    <div style={{ border: '1px solid #d9d9d9', padding: '8px 16px', height: 300, overflowY: 'auto' }}>
        <Checkbox.Group
            style={{ width: '100%', height: '100%' }}
            defaultValue={value}
            value={selectedKeys}
            onChange={onChange}
        >
            <Row>
                {
                    data.map(o =>
                        (
                            <Col style={{ padding: '5px 0' }} key={o.value} span={8}>
                                <Checkbox value={o.value}>{o.label}</Checkbox>
                            </Col>
                        )
                    )
                }
            </Row>
        </Checkbox.Group>
    </div>
  ) : null;
};

export default RoleSelect
