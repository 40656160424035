import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './index.less';
import { store } from './models/store';
import AppRouters from './views/AppRouters';




ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <ConfigProvider locale={zhCN}>
        <AppRouters />
      </ConfigProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
