import { Modal } from 'antd';
import React from 'react';
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { paginate } from '../../../utils/utils';
import { SysUser } from '../def';
import { CRUD_TYPE } from './common';
import DataForm from './DataForm';
import DataTable from './DataTable';
import DeptForm from './DeptForm';
import RespForm from './RespForm';
import RoleForm from './RoleForm';

const UserPage: React.FC = () => {

  const crud = useCrud<CRUD_TYPE>();
  const pager = usePageTable<SysUser>((opt) => paginate(apiurl.upgramurl.user.find, opt));

  return (
    <div className="content-root">
      {
        crud.mode && ['add', 'edit'].includes(crud.mode) ? (
          <Modal
            visible
            footer={null}
            style={{ top: 20 }}
            title={crud.mode === 'add' ? '新增用户' : '修改用户'}
            onCancel={() => crud.goto(null, null)}
          >
            <DataForm crudCtx={crud} pagerCtx={pager} />
          </Modal>
        ) : null
      }
      {
        crud.mode === 'allotRole' ? (
          <RoleForm key={crud.record.id} crudCtx={crud} pagerCtx={pager} />
        ) : null
      }
      {
        crud.mode == 'allotDept' ? (
          <DeptForm key={crud.record.id} crudCtx={crud} pagerCtx={pager} />
        ) : null
      }
      {
        crud.mode === 'updateResp' ? (
          <RespForm key={crud.record.id} crudCtx={crud} pagerCtx={pager} />
        ) : null
      }
      <DataTable crudCtx={crud} pagerCtx={pager} />
    </div>
  )
};

export default UserPage
