import { Card } from 'antd';
import React from 'react';
import CancelCrud from '../../../components/crud/CancelCrud';
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import { SysPermission } from '../def';
import DataForm from './DataForm';
import DataTable from './DataTable';
import service from '../../../service/auth/permission';
import useRequest from '../../../utils/useRequest';
import axios from 'axios';
import apiurl from '../../../service/apiurl';
import { delEmptyChildren } from '../../../utils/sysutils';


const PermissionPage: React.FC = () => {

  const crud = useCrud();

  const reqCtx = useRequest<SysPermission[]>(() => axios.get(apiurl.upgramurl.permission.find).then(resp => {
    const ret = resp.data?.data || [];
    delEmptyChildren(ret);
    return ret;
  }));

  return (
    <div className="content-root">
      {
        crud.mode ? (
          <Card
            key="new"
            title={`${crud.mode === 'add' ? '新增' : '修改'}权限`}
            extra={<CancelCrud crudCtx={crud} confirm />}
          >
            <DataForm crudCtx={crud} requestCtx={reqCtx} />
          </Card>
        ) : null
      }
      <DataTable crudCtx={crud} requestCtx={reqCtx} />
    </div>
  )
};

export default PermissionPage
