const config = {
  menuConstants: {
    menu: [],
    menuIdMap: {},
    menuUrlMap: {},
    menuTree: [],
  }, // todel
  defaultHomePage: '/base/p', // 默认首页
  cacheExpireTime: 1000 * 60 * 60 * 3, // 默认缓存时间3小时
};

export default config;
