import JsonApi from '../../utils/jsonapi';
import CachePromise from '../../utils/cachepromise';
import { message } from 'antd';
import apiurl from '../apiurl';
import { delEmptyChildren } from '../../utils/sysutils';
import config from '../../config';

class Service extends JsonApi {

  constructor() {
    super();

    this.cacheList = new CachePromise(() => this.httpget(apiurl.upgramurl.permission.find, {}, true), config.cacheExpireTime);
    this.cachePermissions = new CachePromise(() => this.httpget(apiurl.upgramurl.permission.findPermissions, {}, true), config.cacheExpireTime);
  }

  async list() {
    const { data, code, msg } = await this.cacheList.get() || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return { list: [], totalRow: 0 }
    }
    delEmptyChildren(data);
    return { list: data, totalRow: data.length }
  }

  async list2() {
    const { data, code, msg } = await await this.cacheList.get() || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return []
    }

    return data
  }

  async permissions() {
    const { data, code, msg } = await this.cachePermissions.get() || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return []
    }
    return data;
  }

  async add(params) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.permission.save, params) || {};
    if (code !== 200) {
      message.error(msg || '新增失败');
      return null;
    }
    message.success('新增成功');
    return true;
  }

  async edit(params) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.permission.edit, params) || {};
    if (code !== 200) {
      message.error(msg || '修改失败');
      return null;
    }
    message.success('修改成功');
    return true;
  }

  async del(id) {
    const { code, msg } = await this.httppost(`${apiurl.upgramurl.permission.del}/${id}`) || {};
    if (code !== 200) {
      message.error(msg || '删除失败');
      return null;
    }
    message.success('删除成功');
    return true;
  }

  async restore(id) {
    const { code, msg } = await this.httpget(apiurl.upgramurl.permission.restore, { id }) || {};
    if (code !== 200) {
      message.error(msg || '恢复失败');
      return null;
    }
    message.success('恢复成功');
    return true;
  }

  async getMaxOrderNum() {
    const { data, code, msg } = await this.httpget(apiurl.upgramurl.permission.getMaxOrderNum) || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return 999
    }
    return data
  }

  clearCache() {
    this.cacheList.setNull();
    this.cachePermissions.setNull();
  }
}

const permissionService = new Service();
export default permissionService;


