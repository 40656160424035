import { Breadcrumb } from 'antd';
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom';

const AppBreadcrumb: React.FC<{
  menu: any;
  menuIndexes: any
}> = ({ menu, menuIndexes }) => {

  const menulist = useMemo(() => {
    const ret: any = [];
    let o = menu;
    for (const id of menuIndexes) {
      const hit = o.find((m: any) => m.id === id);
      ret.push(hit);
      o = hit.children;
    }
    return ret;
  }, [menu, menuIndexes])

  return (
    <Breadcrumb className="app-breadcrumb">
      {
        menulist.map((m: any) => (
          <Breadcrumb.Item key={m.id}>
            {
              m.path ? <Link to={m.path}>{m.title}</Link> : m.title
            }
          </Breadcrumb.Item>
        ))
      }
    </Breadcrumb>
  )
}

export default AppBreadcrumb