import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../models/store';
import apiurl from '../../../service/apiurl';



const AMap = (window as any).AMap;


const MapTrace: React.FC<{
  map: any,
  user: {
    id: number,
    name: string,
  }
}> = ({ map, user }) => {
  const [data, setData] = useState<{ id: number, lng: number, lat: number, address: string }[]>();

  const time = useSelector((s: RootState) => s.usergps.time);
  const cnt = useRef(1);

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    cnt.current += 1;
    const curCnt = cnt.current;

    axios.post(apiurl.usergps.list, {
      stm: time + ' 00:00:00',
      etm: time + ' 23:59:59',
      userId: user.id
    }).then(resp => {
      if (curCnt === cnt.current && resp.data?.data) {
        setData(resp.data.data);
      }
    })
  }, [time]);

  const lineRef = useRef<any>(null);

  useEffect(() => {
    if (!map || !data) {
      return;
    }

    const path = data.map(o => [o.lng, o.lat]);

    if (!path || path.length == 0) {
      return;
    }

    const line = new AMap.Polyline({
      map,
      path,
      showDir: true,
      strokeColor: "#28F",
      strokeWeight: 6,
    });
    map.setFitView([line]);
    lineRef.current = line;


    const p = new AMap.Marker({
      map,
      position: path[path.length - 1]
    });
    p.setLabel({
      offset: new AMap.Pixel(0, 4),
      content: user.name,
      direction: 'bottom'
    });

    map.setFitView([line]);

    return () => {
      map.remove(line);
      map.remove(p);
    }
  }, [map, data]);

  const zoomTo = () => {
    if (data && data.length) {
      if (lineRef.current) {
        map.setFitView([lineRef.current]);
      }
      dispatch.usergps.setInfoView({
        userName: user.name,
        path: data,
        time
      })
    }
  }

  return (
    <div
      style={{
        cursor: 'pointer',
        padding: '4px 8px',
        borderRadius: 4,
        border: '1px solid grey',
        color: data?.length ? '#000' : '#aaa',
        marginRight: 8
      }}
      onClick={zoomTo}
    >
      {user.name}
    </div>
  )
}

export default MapTrace;