import { Button, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import StrictlyTreeSelect from '../../../components/common/tree/StrictlyTreeSelect';
import apiurl from '../../../service/apiurl';
import { getPermissionTreeData } from '../../../utils/sysutils';
import useRequest from '../../../utils/useRequest';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { SysRolePermission } from '../def';
import { ICompProps } from './common';

const PermissionForm: React.FC<ICompProps> = ({
  crudCtx,
  pagerCtx
}) => {

  const { data } = useRequest(getPermissionTreeData);

  const [ids, setIds] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);


  const initValue = useMemo(() => {
    if (crudCtx?.record?.ids) {
      return crudCtx?.record?.ids.map((o: any) => `${o}`)
    }
    return [];
  }, [crudCtx?.record?.ids])


  const submit = async () => {
    if (ids.length === 0) {
      return;
    }
    const id: number = crudCtx.record.id;
    const params: SysRolePermission[] =
      ids.map(d => ({ roleId: id, permissionId: Number(d) }))
        .filter(f => f.permissionId !== 0);;

    saveOrUpdateInPager(apiurl.upgramurl.rolePermission.save, params, pagerCtx, crudCtx, setLoading);
  }

  return (
    <Modal
      visible
      title='分配角色'
      onCancel={() => crudCtx.goto(null, null)}
      footer={[
        <Button key="submit" type="primary" disabled={ids.length === 0} onClick={submit} loading={loading}>提交</Button>,
        <Button key="back" onClick={() => crudCtx.goto(null, null)}>取消</Button>
      ]}
    >
      {
        data ? (
          <StrictlyTreeSelect data={data} value={initValue} onChange={(e: any) => setIds(e)} />
        ) : null
      }
    </Modal>
  )
}

export default PermissionForm