import {
  DownloadOutlined,
  FileAddOutlined,
  SearchOutlined
} from '@ant-design/icons';
import ProForm, { ProFormDatePicker, ProFormDateRangePicker, ProFormDateTimeRangePicker, ProFormText } from '@ant-design/pro-form';
import { Button, Card, Divider, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useMemo } from 'react';
import OpButton, { DelOpButton } from '../../../components/crud/OpButton';
import { CrudContext } from '../../../components/crud/useCrud';
import { PageTableContext } from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { delInPager } from '../../../utils/utils';
import { TrainRes } from '../def';
import { CrudType, ICompProps } from './_';


const resPath = (path: string) => {
  return 'http://owrres.oss-cn-shenzhen.aliyuncs.com/' + path.replace(/[+]/g, '%2B');
}


const DataTable: React.FC<ICompProps> = ({ pagerCtx, crudCtx }) => {

  const columns = useMemo<ColumnsType<TrainRes>>(() => [
    { key: 'name', dataIndex: 'name', title: '文件名', width: 240 },
    { key: 'adnm', dataIndex: 'adnm', title: '政区名称', width: 120 },
    { key: 'remark', dataIndex: 'remark', title: '备注', width: 360 },
    { key: 'userName', title: '上传人', width: 120, render: rec => rec.sysUser?.user },
    { key: 'createTime', dataIndex: 'createTime', title: '上传时间', width: 120, render: val => moment(val).format('YYYY-MM-DD HH:mm') },
    {
      key: 'op', title: '操作', align: 'center', width: 120, render: rec => (
        <Space split={<Divider type="vertical" />}>
          <DelOpButton onClick={() => delInPager(`${apiurl.trainres.del}`, { id: rec.id }, pagerCtx)} />
          <OpButton onClick={() => window.open(resPath(rec.path))} icon={<DownloadOutlined />} />
        </Space>
      )
    },
  ], []);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={async (data) => {
            const { time, ...params } = data;

            if (time && time[0]) {
              params.stm = time[0] + ' 00:00:00'
            }
            if (time && time[1]) {
              params.etm = time[1] + ' 23:59:59'
            }

            pagerCtx.search({
              search: params
            })
          }}
          submitter={false}
        >
          <ProFormText label="文件名称" name="name" />

          <ProFormDateRangePicker
            name="time"
            label="时间"
          />

          <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
          <div style={{ flexGrow: 1 }}></div>
          <Button icon={<FileAddOutlined />} onClick={() => crudCtx.goto('add', {})}>上传</Button>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="adcd" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable;
