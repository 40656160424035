import { CrudContext } from "../../../components/crud/useCrud";
import { PageTableContext } from "../../../components/crud/usePageTable";
import { SysRole } from "../def";

export type CRUD_TYPE = 'add' | 'edit' | 'auth';

export type ICompProps = {
  pagerCtx: PageTableContext<SysRole>;
  crudCtx: CrudContext<CRUD_TYPE>
}

export type FormShape = {
  id?: number;
  roleName: string;
  remark?: string;
  del: number;
};

export const DEF_VALUE: FormShape = {
  roleName: '',
  remark: '',
  del: 0,
}