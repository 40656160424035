import ProForm, {
  ProFormDigit, ProFormInstance, ProFormRadio, ProFormText,
  ProFormTreeSelect
} from '@ant-design/pro-form';
import { TreeSelect } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import { hiddenType, permissionType, systype } from '../../../service/appconsts';
import service from '../../../service/auth/permission';
import { getLabelValueByObj, getPermissionTreeData } from '../../../utils/sysutils';
import { FormShape, ICompProps } from './common';


const typeOptions = getLabelValueByObj(systype);
const hiddenTypeOptions = getLabelValueByObj(hiddenType);
const permissionTypeOptions = getLabelValueByObj(permissionType);


const DataForm: React.FC<ICompProps> = ({ requestCtx, crudCtx }) => {
  const formRef = useRef<ProFormInstance<FormShape>>();
  const record = crudCtx.record || { type: 1, ptype: 0, hidden: 0, parentId: 0 };
  const [type, setType] = useState<number>(record.type);
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<React.Key[]>([record.parentId]);

  useEffect(() => {
    if (crudCtx.mode === 'add') {
      service.getMaxOrderNum().then(orderNum => {
        formRef?.current?.setFieldsValue({ orderNum })
      });
    }
  }, [crudCtx.mode]);

  const onTreeExpand = (expandedKeysValue: React.Key[]) => {
    setTreeExpandedKeys(expandedKeysValue);
  };

  return record ? (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={
          async (values) => {
            let ret: any;
            if (crudCtx.mode === 'add') {
              ret = await service.add(values)
            } else {
              ret = await service.edit(values)
            }
            if (ret) {
              service.clearCache();
              crudCtx.goto(null, null);
              requestCtx.refresh();
            }
          }}
        onReset={() => formRef?.current?.setFieldsValue({
          type: 1,
          ptype: 0,
          title: '',
          icon: '',
          hidden: 0,
          parentId: 0
        })}
      >
        {
          crudCtx.mode === 'edit' && (<ProFormText name="id" hidden />)
        }
        <ProFormRadio.Group
          name="type"
          label="类型"
          width={DEF_INPUT_LEN}
          options={typeOptions}
          rules={[{ required: true }]}
          fieldProps={{ onChange: (e) => setType(e.target.value) }}
        />
        <ProFormRadio.Group
          name="ptype"
          label="权限类型"
          width={DEF_INPUT_LEN}
          options={permissionTypeOptions}
          rules={[{ required: true }]}
        />
        <ProFormText
          name="title"
          label={systype[type]}
          width={DEF_INPUT_LEN}
          fieldProps={{ allowClear: true }}
          placeholder={`请输入${systype[type]}名称`}
          rules={[{ required: true, message: `${systype[type]}名称不能为空` }]}
        />
        {
          type === 2 && (
            <ProFormText
              label="权限标识"
              name="perms"
              width={DEF_INPUT_LEN}
              placeholder="例如:sys:menu:add"
              fieldProps={{ allowClear: true }}
            />
          )
        }
        {
          type === 1 && (
            <ProFormText
              label="路由"
              name="path"
              width={DEF_INPUT_LEN}
              placeholder="请输入路由"
              fieldProps={{ allowClear: true }}
              rules={[{ required: true, message: '路由不能为空' }]}
            />
          )
        }
        {
          type === 0 && (
            <ProFormText
              label="重定向路由"
              name="redirect"
              width={DEF_INPUT_LEN}
              fieldProps={{ allowClear: true }}
            />
          )
        }
        <ProFormTreeSelect
          label="上级菜单"
          name="parentId"
          request={getPermissionTreeData}
          width={DEF_INPUT_LEN}
          fieldProps={{
            treeExpandedKeys,
            onTreeExpand,
            showCheckedStrategy: TreeSelect.SHOW_PARENT,
            fieldNames: { label: 'title', value: 'id', children: 'children' },
            getPopupContainer: triggerNode => triggerNode.parentNode || document.body
          }}
          rules={[{ required: true }]}
        />
        {
          type !== 2 && (
            <ProFormText
              label="图标"
              name="icon"
              width={DEF_INPUT_LEN}
              fieldProps={{ allowClear: true }}
            />
          )
        }
        <ProFormDigit
          label="排序"
          name="orderNum"
          min={0}
          width={DEF_INPUT_LEN}
          rules={[{ required: true, message: '排序不能为空' }]}
        />
        <ProFormRadio.Group
          label="是否隐藏"
          name="hidden"
          width={DEF_INPUT_LEN}
          options={hiddenTypeOptions}
          rules={[{ required: true }]}
        />
      </ProForm>
    </CenterForm>
  ) : null;
};

export default DataForm;
