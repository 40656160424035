const apiurl = {
  /*用户*/
  upgramurl: {
    /*登陆*/
    login: {
      auth: '/service/sysLogin/login',
    },
    /*用户管理*/
    user: {
      find: '/service/sysUser/page',
      list: '/service/sysUser/list',
      save: '/service/sysUser/save',
      edit: '/service/sysUser/edit',
      editKai: '/service/sysUser/updateKai',
      del: '/service/sysUser/del',
      reset: '/service/sysUser/resetPassword',
      changePwd: '/service/sysUser/changePwd',
      restore: '/service/sysUser/restore',
      findUserRoleName: '/service/sysUser/findUserRoleName',
      updateResp: '/service/sysUser/updateResp',
      contactByAdcd: '/service/sysUser/contactByAdcd',
      export: '/service/sysUser/export'
    },
    /*角色管理*/
    role: {
      find: '/service/sysRole/queryPage',
      list: '/service/sysRole/list',
      save: '/service/sysRole/save',
      edit: '/service/sysRole/edit',
      del: '/service/sysRole/del',
      restore: '/service/sysRole/restore',
    },
    /*权限管理*/
    permission: {
      find: '/service/sysPermission/findTrees',
      findPermissions: '/service/sysPermission/findPermissions',
      findAnNiu: '/service/sysPermission/findAnNiu',
      getMaxOrderNum: '/service/sysPermission/getMaxOrderNum',
      save: '/service/sysPermission/save',
      edit: '/service/sysPermission/edit',
      del: '/service/sysPermission/del',
      restore: '/service/sysPermission/restore',
    },
    /*部门管理*/
    dept: {
      find: '/service/sysDept/queryByDeptName',
      tree: '/service/sysDept/query',
      save: '/service/sysDept/save',
      edit: '/service/sysDept/edit',
      del: '/service/sysDept/del',
      restore: '/service/sysDept/restore',
      deptUserTree: '/service/sysDept/deptUserTree',
    },
    /*用户角色*/
    userRole: {
      save: '/service/sysUserRole/save'
    },
    /*用户部门*/
    userDept: {
      save: '/service/sysUserDept/save',
    },
    /*角色权限*/
    rolePermission: {
      save: '/service/sysRolePermission/save',
    },
    /*用户登录日志*/
    sysLoginLog: {
      page: '/service/sysLoginLog/page',
    },
  },

  base: {
    adinfo: {
      find: '/service/base/adinfo/find',
      list: '/service/base/adinfo/list',
      save: '/service/base/adinfo/save',
      edit: '/service/base/adinfo/edit',
      del: '/service/base/adinfo/del',
      restore: '/service/base/adinfo/restore',
      adlist: '/service/base/adinfo/adlist',
      aboveList: '/service/base/adinfo/aboveList',
    },
  },

  onlineStatistics:{
    find: '/service/sysOnlineInfo/page',
  },

  usermsg: {
    find: '/service/base/userMsg/page',
    statToday: '/service/base/userMsg/statToday',
    get: '/service/base/userMsg/get',
    setCheck: '/service/base/userMsg/setCheck',
    cancelCheck: '/service/base/userMsg/cancelCheck',
  },

  trainres: {
    find: '/service/base/trainRes/page',
    get: '/service/base/trainRes/get',
    del: '/service/base/trainRes/del',
    restore: '/service/base/trainRes/restore',
    upload: '/service/base/trainRes/upload',
  },

  usergps: {
    list: '/service/base/userGps/list',
  }
};

export default apiurl;
