export type objEnum = {
  [key: string]: any,
}

export const systype: objEnum = {
  0: '目录',
  1: '菜单',
  2: '按钮'
};

export const permissionType: objEnum = {
  0: 'WEB端',
  1: 'APP端'
};

export const hiddenType: objEnum = {
  0: '显示',
  1: '隐藏'
};

export const userStatus: objEnum = {
  0: '启用',
  1: '禁用'
};

export const levelType: objEnum = {
  1: '一般',
  2: '重要',
  3: '紧急'
};

export const messageStatus: objEnum = {
  1: '已发送',
  2: '已读'
};

export const sysLoginLogStatus: objEnum = {
  0: '成功',
  1: '失败',
};

export const sysLoginLogType: objEnum = {
  1: 'WEB端',
  2: 'App端',
};



export const userMsgType: objEnum = {
  1: '24小时气象预报预警',
  2: '短时雷达气象预报预警',
};

export const pType = {
  '党政一把手': 1 << 0,
  '分管责任人': 1 << 1,
  '值班人员': 1 << 2,
  '包保责任人': 1 << 3,
  '水管站负责人': 1 << 4,
  '信息检测员': 1 << 5,
}

export const pTypeStr = {
  [1 << 0]: '党政一把手',
  [1 << 1]: '分管责任人',
  [1 << 2]: '值班人员',
  [1 << 3]: '包保责任人',
  [1 << 4]: '水管站负责人',
  [1 << 5]: '信息检测员',
}