
import { Modal } from 'antd';
import AdcdTree from './AdcdTree';
import DataTable from './DataTable';
import SearchBar from './SearchBar';
import { usePageContext } from './_';

const ZrrPage = () => {

  const ctx = usePageContext();

  return (
    <div className="content-root">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 250 }}>
          <AdcdTree ctx={ctx} />
        </div>
        <div style={{ width: 16 }}></div>
        <div style={{ flexGrow: 1 }}>
          <SearchBar ctx={ctx} />
          <div className="card-h-margin" />
          <DataTable ctx={ctx} />
        </div>
      </div>
    </div>
  )
}

export default ZrrPage