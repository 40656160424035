import { FolderAddOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Radio, Badge, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useMemo } from 'react';
import OpButton, { DelOpButton, EditOpButton, RestoreOpButton } from '../../../components/crud/OpButton';
import apiurl from '../../../service/apiurl';
import { pType, pTypeStr } from '../../../service/appconsts';
import { renYYYYMMDD } from '../../../utils/renutil';
import { delInPager, restoreInPager } from '../../../utils/utils';
import { SysUser, SysUserDept } from '../../auth/def';
import { ICompProps, TableOption } from './_';

const DataTable: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {

  const { pager, crud, isAdmin } = ctx;

  const columns = useMemo<ColumnsType<SysUser>>(() => {
    const ret: ColumnsType<SysUser> = [
      { title: '姓名', key: 'user', dataIndex: 'user' },
      { title: '归属行政区划', key: 'adnm', dataIndex: 'adnm' },
      // { title: '登录账户', key: 'name', dataIndex: 'name' },
      {
        title: '联系电话', key: 'phone', render: (rec: SysUser) => (
          !isAdmin && rec.respDuty && (rec.respDuty & pType.党政一把手) === 0 ? null : rec.phone
        )
      },
      {
        title: '责任人类型', key: 'respDuty', dataIndex: 'respDuty', render: (val: number) => (

          Object.keys(pTypeStr).map(o => (val & parseInt(o)) ? pTypeStr[o as any] : null).filter(Boolean).join(', ')

        )
      },
      { title: '职务', key: 'position', dataIndex: 'position' },
      { title: '职责', key: 'duty', dataIndex: 'duty' },
      { title: '在线状态', key: 'onlineState', dataIndex: 'onlineState',render: val => (val === 0 ? <Badge color='green' text="在线" /> : val===1 ? <Badge color='red' text="离线" />:'-') },
      { title: '最后在线时间', key: 'lastOnlineTime', dataIndex: 'lastOnlineTime', render: val =>val?moment(val).format('YYYY-MM-DD HH:mm:ss'):'-' },
    ];
    return ret;
  }, []);

  return (
    <Card >
      <Table
        key={ctx.tableView}
        rowKey="id"
        columns={columns}
        {...pager.tableProps}
      />
    </Card>
  )
};

export default DataTable;
