import {
  FolderAddOutlined, PlusOutlined,
  SearchOutlined,
  UserOutlined
} from '@ant-design/icons';
import ProForm, { ProFormCheckbox, ProFormText } from '@ant-design/pro-form';
import { Button, Card, Divider, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import OpButton, { DelOpButton, EditOpButton, RestoreOpButton } from '../../../components/crud/OpButton';
import { CrudContext } from '../../../components/crud/useCrud';
import { PageTableContext } from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { renYYYYMMDD } from '../../../utils/renutil';
import { delInPager, restoreInPager } from '../../../utils/utils';
import { SysUser, SysUserRole } from '../def';
import { DEF_VALUE } from './common';

type IProps = {
  pagerCtx: PageTableContext<SysUser>;
  crudCtx: CrudContext
}

const DataTable: React.FC<IProps> = ({ pagerCtx, crudCtx }) => {

  const columns = useMemo<ColumnsType<SysUser>>(() => [
    { title: '登录账户', key: 'name', dataIndex: 'name' },
    { title: '姓名', key: 'user', dataIndex: 'user' },
    { title: '联系电话', key: 'phone', dataIndex: 'phone' },
    { title: '职务', key: 'position', dataIndex: 'position' },
    { title: '职责', key: 'duty', dataIndex: 'duty' },
    { title: '在职单位', key: 'org', dataIndex: 'org' },
    { title: '归属行政区划', key: 'adnm', dataIndex: 'adnm' },
    { title: '创建时间', key: 'createTime', dataIndex: 'createTime', align: 'center', render: renYYYYMMDD },
    {
      title: '角色', key: 'sysRoles', render: rec => (
        rec.sysUserRoles && rec.sysUserRoles.length > 0 ? rec.sysUserRoles.map((r: SysUserRole) => r.roleName).join(',') : ''
      )
    },
    // { title: '部门', key: 'sysDepts', render: rec => (
    //    rec.sysUserDepts && rec.sysUserDepts.length > 0 ? rec.sysUserDepts.map((r: SysUserDept) => r.deptName).join(',') : ''
    //   )
    // },
    {
      title: '操作', key: '_', render: rec => (
        <Space split={<Divider type="vertical" />}>
          {
            rec?.del === 0 && (
              <Space split={<Divider type="vertical" />}>
                <OpButton tooltip='分配角色' icon={<FolderAddOutlined />} onClick={
                  () => crudCtx.goto('allotRole',
                    { id: rec.id, roleIds: rec.roleIds.map((m: number) => String(m)) })} />
                {/* <Tooltip title="分配部门">
                            <Button icon={<FolderAddOutlined />} type="link" onClick={
                                () => crudCtx.goto('allotDept',
                                    { id: rec.id, deptIds: rec.deptIds.map((m: number) => String(m)) })}/>
                        </Tooltip> */}
                <OpButton tooltip='责任人角色' icon={<UserOutlined />} onClick={
                  () => crudCtx.goto('updateResp', rec)} />
                <EditOpButton onClick={() => crudCtx.goto('edit', rec)} />
                <DelOpButton onClick={() => delInPager(apiurl.upgramurl.user.del, { id: rec.id }, pagerCtx)} />
              </Space>
            )
          }
          {
            rec?.del === 1 && (
              <RestoreOpButton onClick={() => restoreInPager(apiurl.upgramurl.user.restore, { id: rec.id }, pagerCtx)} />
            )
          }
        </Space>
      )
    }
  ], []);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={
            async (data) => {
              pagerCtx.search({
                search: {
                  user: data.user,
                  showall: data.showall ? '1' : '0',
                }
              })
            }
          }
          submitter={false}
        >
          <ProFormText label="姓名" name="user" />
          <ProFormCheckbox label="全部" name="showall" />
          <Space>
            <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
            <Button icon={<PlusOutlined />} onClick={() => crudCtx.goto('add', { ...DEF_VALUE })}>新建</Button>
          </Space>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="id" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable;
