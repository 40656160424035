import JsonApi from '../../utils/jsonapi';
import CachePromise from '../../utils/cachepromise';
import { message } from 'antd';
import apiurl from '../apiurl';
import { delEmptyChildren } from '../../utils/sysutils';
import config from '../../config';

class Service extends JsonApi {

    constructor() {
        super();

        this.cacheDeptTreeList = new CachePromise(() => this.httppost(apiurl.upgramurl.dept.find), config.cacheExpireTime);
    }

    async list(params = {}) {
        const { data, code, msg } = await this.httppost(apiurl.upgramurl.dept.find, params) || {};
        if (code !== 200) {
            message.error(msg || '请求失败');
            return { list: [], totalRow: 0 };
        }
        delEmptyChildren(data);
        return { list: data, totalRow: data.length };
    }

    async deptTreeList() {
        const { data, code, msg } = await this.cacheDeptTreeList.get() || {};
        if (code !== 200) {
            message.error(msg || '请求失败');
            return [];
        }
        return data;
    }

    async add(params) {
        const { code, msg } = await this.httppost(apiurl.upgramurl.dept.save, params) || {};
        if (code !== 200) {
            message.error(msg || '新增失败');
            return null;
        }
        message.success('新增成功');
        return true;
    }

    async edit(params) {
        const { code, msg } = await this.httppost(apiurl.upgramurl.dept.edit, params) || {};
        if (code !== 200) {
            message.error(msg || '修改失败');
            return null;
        }
        message.success('修改成功');
        return true;
    }

    async del(id) {
        const { code, msg } = await this.httppost(`${apiurl.upgramurl.dept.del}/${id}`) || {};
        if (code !== 200) {
            message.error(msg || '删除失败');
            return null;
        }
        message.success('删除成功');
        return true;
    }

    async restore(id) {
        const { code, msg } = await this.httppost(apiurl.upgramurl.dept.restore, { id }) || {};
        if (code !== 200) {
            message.error(msg || '恢复失败');
            return null;
        }
        message.success('恢复成功');
        return true;
    }

    async deptUserTree() {
        const { code, data, msg } = await this.httpget(apiurl.upgramurl.dept.deptUserTree) || {};
        if (code !== 200) {
            message.error(msg || '获取数据失败');
            return null;
        }
        delEmptyChildren(data);
        return data.filter(d => d.children && d.children.length > 0);
    }

    clearCache() {
        this.cacheDeptTreeList.setNull();
    }
}

const deptService = new Service();
export default deptService;
