import { Modal } from 'antd';
import axios from 'axios';
import React from 'react';
import useCrud from '../../../components/crud/useCrud';
import apiurl from '../../../service/apiurl';
import { delEmptyChildren } from '../../../utils/sysutils';
import useRequest from '../../../utils/useRequest';
import DataForm from './DataForm';
import DataTable from './DataTable';


const DeptPage: React.FC = () => {

  const crud = useCrud();

  const reqestCtx = useRequest((params) => axios.post(apiurl.upgramurl.dept.find, params || {}).then(
    resp => {
      const data = resp.data?.data || [];
      delEmptyChildren(data);
      return data;
    }
  ))

  return (
    <div className="content-root">
      {
        crud.mode ? (
          <Modal
            visible
            footer={null}
            title={crud.mode === 'add' ? '新增部门' : '修改部门'}
            onCancel={() => crud.goto(null, null)}
          >
            <DataForm crudCtx={crud} requestCtx={reqestCtx} />
          </Modal>
        ) : null
      }
      <DataTable crudCtx={crud} requestCtx={reqestCtx} />
    </div>
  )
};

export default DeptPage
