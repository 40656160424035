import React, { useEffect } from 'react';
import useCrud from '../../../components/crud/useCrud';
import usePageTable from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { httppost } from '../../../utils/request';
import { paginate } from '../../../utils/utils';
import { Adinfo } from '../def';
import DataTable from './DataTable';
import moment from 'moment';

const Page: React.FC = () => {

  // useEffect(()=>{
  //   getadd()
  // },[])

  // const getadd = async ()=>{
  //   const a = await httppost('/service/sysOnlineInfo/add')
  //   debugger
  // }
  const defaultOption = {
    "bgTm": moment().format('YYYY-MM-01 HH:mm:ss'),
    "endTm": moment().format('YYYY-MM-DD HH:mm:ss')
  }


  const pager = usePageTable<Adinfo>(async (params) => paginate(apiurl.onlineStatistics.find, params),{search:defaultOption});

  return (
    <div className="content-root">
      <DataTable pagerCtx={pager} defaultOption={defaultOption}/>
    </div>
  )
};

export default Page
