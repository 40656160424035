import { Card, Checkbox } from 'antd'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdcdTreeSelect from '../../../components/common/tree/AdcdTreeSelect'
import { Dispatch, RootState } from '../../../models/store'
import apiurl from '../../../service/apiurl'


const ContactSelector: React.FC = () => {

  const [adcd, setAdcd] = useState<string>();
  const [data, setData] = useState<any>([]);
  const selected = useSelector((s: RootState) => s.usergps.selected);
  const selectedMap = useMemo<{ [key: number]: any }>(
    () => selected.reduce((total: { [key: number]: any }, cur) => {
      total[cur.id] = cur;
      return total;
    }, {}), [selected]);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (!adcd) {
      return;
    }

    axios.post(apiurl.upgramurl.user.contactByAdcd, { adcd }).then((resp) => {
      let data = resp.data?.data || [];
      data = data.filter((o: any) => o.dir == 0)
      setData(data);
    })
  }, [adcd]);

  return (
    <Card bodyStyle={{ minHeight: 600 }}>
      <AdcdTreeSelect style={{ width: '100%', marginBottom: 16 }} onChange={setAdcd} />

      <div>
        {
          data.map((p: any) => (
            <div key={p.id} style={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox
                checked={!!selectedMap[p.id]}
                onChange={
                  (e) => {
                    if (e.target.checked) {
                      dispatch.usergps.addUser({ id: p.id, name: p.nodeName })
                    } else {
                      dispatch.usergps.delUser(p.id);
                    }
                  }
                }
              >
                {p.nodeName}
              </Checkbox>
            </div>
          ))
        }
      </div>
    </Card>
  )
}

export default ContactSelector;

