import ProForm, {
  ProFormInstance, ProFormRadio, ProFormText
} from '@ant-design/pro-form';
import React, { useMemo, useRef } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import apiurl from '../../../service/apiurl';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { phoneValidator } from "../../../utils/validator";
import { Checkbox, Form } from 'antd';
import { PFormShape, ICompProps } from './_';
import AdcdTreeSelect from './AdcdTreeSelect';
import { getLabelValueByObj } from '../../../utils/sysutils';
import { userStatus } from '../../../service/appconsts';

const userStatusOptions = getLabelValueByObj(userStatus);



const AForm: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {
  const { crud, pager } = ctx;

  const formRef = useRef<ProFormInstance<PFormShape>>();

  const submit = async (values: PFormShape) => {
    const { _adinfo, ...params } = values;

    params.adcd = _adinfo.adcd;

    console.log(params);

    saveOrUpdateInPager(apiurl.upgramurl.user.editKai, params, pager, crud);
  }

  const reset = () => formRef?.current?.resetFields();

  const record = useMemo(() => {
    const r = crud.record;
    r._adinfo = {
      adcd: r.adcd,
      adnm: r.adnm,
    }

    return r;
  }, []);

  if (crud.mode !== 'editA') {
    return null;
  }

  return (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={submit}
        onReset={reset}
      >
        <ProFormText name="id" hidden />

        <ProFormText
          name="user"
          label="用户姓名"
          width={DEF_INPUT_LEN}
          placeholder="请输入用户姓名"
          rules={[{ required: true, message: '用户姓名不能为空' }]}
        />
        <ProFormText
          name="name"
          label="登陆名"
          width={DEF_INPUT_LEN}
          rules={[{ required: true }]}
        />

        <Form.Item
          name="_adinfo"
          label="归属行政区划"
          rules={[{ required: true }]}
        >
          <AdcdTreeSelect />
        </Form.Item>

        <ProFormRadio.Group
          label="是否启用"
          name="status"
          width={DEF_INPUT_LEN}
          options={userStatusOptions}
        />
      </ProForm>
    </CenterForm>
  );
};

export default AForm;