import { TreeSelect, TreeSelectProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { TreeNode, Adinfo } from '../../../service/def';
import service from '../../../service/base/adinfo';
import { getAdcdPid } from '../../../utils/tools';

type IProps = TreeSelectProps & {
  // 添加扩展属性 TODO: 异步加载，传进来的值不在列表中时，无法正常回显
  initadnm?: string
}

const AdcdTreeSelect: React.FC<IProps> = (props: IProps) => {
  const [data, setData] = useState<TreeNode[]>([{
    id: '420000000000000', value: '420000000000000', key: '420000000000000', title: '湖北省'
  }]);

  useEffect(() => {
    (async () => {
      if(!props.initadnm){
        return;
      }

      const list: [Adinfo] = await service.aboveList({ adcd: props.value });
      const trees: TreeNode[] = [];

      list.forEach(({adcd, adnm}) => {
        if (data.filter(item => item.value === adcd).length > 0) {
          return;
        }

        trees.push({
          id: adcd,
          value: adcd,
          key: adcd,
          title: adnm,
          pId: getAdcdPid(adcd),
        });
      });

      setData(data.concat(trees));
    })();
  }, [props.initadnm, props.value]);

  const onLoadData = async (param: any) => {
    if (!/000$/.test(param.value)) { // 结尾不是 000 的不用在请求下级了
      return;
    }

    const list: [Adinfo] = await service.adlist({ adcd: param.value });
    const trees: TreeNode[] = [];

    list.forEach(({adcd, adnm}) => {
      if (data.filter(item => item.value === adcd).length > 0) {
        return;
      }

      trees.push({
        id: adcd,
        value: adcd,
        key: adcd,
        title: adnm,
        pId: param.value,
      });
    });

    setData(data.concat(trees));
  }

  return <TreeSelect
    {...props}
    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    treeDataSimpleMode
    loadData={onLoadData}
    treeData={data}
  />;
};

export default AdcdTreeSelect;