import { Button, Form, Input, message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { UAParser } from 'ua-parser-js';
import config from '../../../config';
import { Dispatch } from '../../../models/store';
import './index.less';
// import LogoImg from './img/logo.png'



const Login: React.FC = () => {
  const uaparse = new UAParser();
  const browser = uaparse.getBrowser();
  const os = uaparse.getOS();

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const onFinish = async (values: any) => {
    values.brand = "";
    values.browserVersion = `${browser.name} ${browser.version}`;
    values.deviceId = "";
    values.latitude = "";
    values.longitude = "";
    values.model = "";
    values.operatingSystem = `${os.name} ${os.version}`;

    const ret = await dispatch.auth.login({ ...values, type: 1 });

    if (ret) {
      navigate(config.defaultHomePage);
    } else {

      message.error("登陆失败!");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='login-root'>
      <div className='login-inner'>
        <div className='login-form'>
          <div style={{ textAlign: 'center', marginBottom: 32 }}>
            <div className='login-title'>湖北山洪责任人</div>
            <div className='login-sub-title'>系统登陆</div>
          </div>
          <div style={{ width: 360 }}>
            <Form
              name="basic"
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="用户名"
                name="name"
                rules={[{ required: true, message: '请输入用户名!' }]}
              >
                <Input placeholder="请输入账号" onPressEnter={onFinish} />
              </Form.Item>

              <Form.Item style={{ margin: "30px 0" }}
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
              >
                <Input.Password placeholder="请输入密码" onPressEnter={onFinish} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }} style={{ margin: "40px 0 0 0" }}>
                <Button type="primary" htmlType="submit" block size="large">
                  登陆
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Login
