export default class CachePromise {
  constructor(pf, expireTime) {
    this.curTm = 0;
    this.promise = undefined;
    this.pf = pf;
    this.expireTime = expireTime;
  }

  setNull = () => {
    this.promise = undefined;
  };

  get = async () => {
    const now = Date.now();
    if (this.expireTime && now - this.curTm > this.expireTime) {
      this.promise = this.pf();
      this.curTm = now;
    }
    if (!this.promise) {
      this.promise = this.pf();
      this.curTm = now;
    }

    const data = await this.promise;

    return data;
  }
}
