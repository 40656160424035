import { TableProps } from 'antd';
import { useEffect, useReducer, useRef } from 'react';
import { PageResult, SearchOption } from '../../service/def';


type TableState<T> = {
  abort: boolean;

  data: T[];
  total: number;
  loading: boolean;

  pageSize: number;
  pageNumber: number;
  search: { [key: string]: string };
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
}


export type PageTableContext<T> = {
  tableProps: TableProps<T>,
  search: (params?: SearchOption) => void,
  refresh: () => void,
  initialSearch?: { [key: string]: string },
}

function usePageTable<T>(
  service: (params?: SearchOption) => Promise<PageResult<T>>,
  options: SearchOption = {}
): PageTableContext<T> {
  const [, forceRender] = useReducer(s => s + 1, 1);

  const state = useRef<TableState<T>>({
    abort: false,
    data: [],
    total: 0,
    loading: false,
    pageSize: options.pageSize ?? 10,
    pageNumber: options.pageNumber ?? 1,
    sortField: options.sortField,
    sortOrder: options.sortOrder,
    search: options.search || {},
  });

  useEffect(() => {
    const stateRef = state.current;
    stateRef.abort = false;
    search();

    return () => { stateRef.abort = true; }
  }, []);

  const search = (opt: SearchOption = {}) => {
    const s = state.current;
    if (s.loading || s.abort) {
      return;
    }
    s.loading = true;
    forceRender();

    const pageParams = {
      pageNumber: opt?.pageNumber ?? s.pageNumber,
      pageSize: opt?.pageSize ?? s.pageSize,
      sortField: opt?.sortField ?? s.sortField,
      sortOrder: opt?.sortOrder ?? s.sortOrder,
      search: opt?.search ?? s.search,
    };

    service(pageParams).then((data) => {
      if (!s.abort) {
        s.search = pageParams.search;
        s.sortField = pageParams.sortField;
        s.sortOrder = pageParams.sortOrder;
        s.pageNumber = pageParams.pageNumber;
        s.pageSize = pageParams.pageSize;
        s.data = data.list;
        s.total = data.totalRow;
        s.loading = false;

        forceRender();
      }
    });
  }

  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    const { field, order } = sort;
    if (field && order) {
      const sortOrder: any = order === 'ascend' ? 'asc' : 'desc';
      search({ pageNumber: pagination.current, pageSize: pagination.pageSize, sortOrder, sortField: field });
      return;
    }
    search({ pageNumber: pagination.current, pageSize: pagination.pageSize });
  }

  return {
    tableProps: {
      size: 'small',
      bordered: true,
      dataSource: state.current.data,
      pagination: {
        showTotal: (totalRow: any) => `共${totalRow}条`,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSize: state.current.pageSize,
        current: state.current.pageNumber,
        total: state.current.total,
        pageSizeOptions: ['10', '20', '50', '100'],
        hideOnSinglePage: true,
      },
      loading: state.current.loading,
      onChange: handleTableChange,
    },
    search: (params?: SearchOption) => search({ ...params, pageNumber: 1 }),
    refresh: search,
    initialSearch: options?.search
  }
}

export default usePageTable