import { Card, Checkbox, Tree } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../models/store'
import adinfoService from '../../../service/base/adinfo'
import { Adinfo } from '../../../service/def'
import { ICompProps } from './_'

export interface DataNode {
  title: string;
  key: string;
  value: string;
  isLeaf?: boolean;
  children?: DataNode[];
}

export const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  })

const AdcdTree: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {



  const user = useSelector((s: RootState) => s.auth.user);

  const [treeData, setTreeData] = useState<DataNode[]>(() => user ? [{
    key: user.adcd,
    title: user.adnm,
    value: user.adcd,
  }] : []);

  const onTreeLeafLoadData = async ({ key, value }: any) => {

    if (!/000000$/.test(value)) {
      return;
    }

    const list: Adinfo[] = await adinfoService.adlist({ adcd: value });

    if (list && list.length) {
      const newNodes: DataNode[] = list.map(({ adcd, adnm }) => ({
        title: adnm,
        key: adcd,
        value: adcd,
        isLeaf: !adcd.endsWith('000000')
      }))

      console.log(newNodes)
      setTreeData(origin => updateTreeData(origin, key, newNodes));
    }
  }

  useEffect(() => {
    if (treeData[0]) {
      onTreeLeafLoadData(treeData[0])
    }
  }, []);

  return (
    <div style={{ padding: '16px 0 16px 16px', backgroundColor: '#fff' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16, marginBottom: 8 }}>
        <span>行政区划过滤:</span>
        <Checkbox
          defaultChecked={ctx.exactRef.current}
          onChange={e => {
            console.log(e)
            ctx.searchAdcd(ctx.adcdRef.current, e.target.checked)
          }}>
          仅当前级
        </Checkbox>
      </div>

      {
        treeData.length > 0 ? (
          <Tree
            onSelect={vals => {
              ctx.searchAdcd(vals[0] as string, ctx.exactRef.current)
            }}
            defaultExpandAll
            showLine
            multiple={false}
            style={{ height: 'calc(100vh - 200px)', minHeight: 400, overflowY: 'auto' }}
            loadData={onTreeLeafLoadData}
            treeData={treeData}
          />
        ) : null
      }

    </div>
  )
}

export default AdcdTree