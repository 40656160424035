import React from 'react';
import ContactSelector from './ContactSelector';
import GpsMap from './GpsMap';
import SearchBar from './SearchBar';
import TraceInfo from './TraceInfo';

const UserGps: React.FC = () => {

  return (
    <div className="content-root">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 300 }}>
          <ContactSelector />
        </div>
        <div style={{ width: 16 }}></div>
        <div style={{ flexGrow: 1 }}>
          <SearchBar />
          <GpsMap />
        </div>
      </div>
      <TraceInfo />
    </div>
  )
}

export default UserGps