import ProForm, { ProFormInstance, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import React, { useRef } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import apiurl from '../../../service/apiurl';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { DEF_VALUE, FormShape, ICompProps } from './common';

const DataForm: React.FC<ICompProps> = ({ requestCtx, crudCtx }) => {
  const formRef = useRef<ProFormInstance<FormShape>>();

  const record = crudCtx.record;

  const submit = async (values: FormShape) => {

    const url = crudCtx.mode === 'add' ? apiurl.upgramurl.dept.save : apiurl.upgramurl.dept.edit;
    saveOrUpdateInPager(url, values, requestCtx, crudCtx);
  }

  return record ? (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={submit}
        onReset={() => formRef?.current?.setFieldsValue(DEF_VALUE)}
      >
        {
          crudCtx.mode === 'edit' && (<ProFormText name="id" hidden />)
        }
        <ProFormText name="parentId" hidden />
        <ProFormText name="parentName" label="上级部门" readonly />
        <ProFormText
          label="部门名称"
          name="deptName"
          width={DEF_INPUT_LEN}
          placeholder="请输入部门名称"
          fieldProps={{ allowClear: true }}
          rules={[{ required: true, message: '部门名称不能为空' }]}
        />
        <ProFormTextArea
          name="remark"
          label="部门描述"
          placeholder="请输入部门描述"
          fieldProps={{
            allowClear: true,
            autoSize: { minRows: 2, maxRows: 5 }
          }}
        />
      </ProForm>
    </CenterForm>
  ) : null;
};

export default DataForm;
