import { Card, DatePicker } from 'antd'
import moment from 'moment';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../models/store';

const SearchBar = () => {

  const dispatch = useDispatch<Dispatch>();
  const time = useSelector((s: RootState) => s.usergps.time);

  return (
    <Card style={{ marginBottom: 16 }}>
      <span style={{ marginRight: 8 }}>查询时间:</span>
      <DatePicker
        value={moment(time)}
        onChange={(val) => {
          if (val) {
            dispatch.usergps.setTime(val?.format('YYYY-MM-DD'))
          }
        }}
      />
    </Card>
  )
}

export default React.memo(SearchBar);