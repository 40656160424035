import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import apiurl from '../../../service/apiurl';
import { getRoleOptions } from '../../../utils/sysutils';
import useRequest from '../../../utils/useRequest';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { SysUserRole } from '../def';
import { ICompProps } from './common';
import RoleSelect from './RoleSelect';

const RoleForm: React.FC<ICompProps> = ({
  crudCtx,
  pagerCtx
}) => {

  const { data } = useRequest(getRoleOptions);

  const [roleIds, setRoleIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async () => {
    if (roleIds.length === 0) {
      return;
    }
    const userId: number = crudCtx.record.id;
    const params: SysUserRole = { userId, ids: roleIds };

    saveOrUpdateInPager(apiurl.upgramurl.userRole.save, params, pagerCtx, crudCtx, setLoading);
  }

  return (
    <Modal
      visible
      title='分配角色'
      onCancel={() => crudCtx.goto(null, null)}
      footer={[
        <Button key="submit" type="primary" disabled={roleIds.length === 0} onClick={submit} loading={loading}>提交</Button>,
        <Button key="back" onClick={() => crudCtx.goto(null, null)}>取消</Button>
      ]}
    >
      {
        data ? (
          <RoleSelect data={data} value={crudCtx?.record?.roleIds || []} change={e => setRoleIds(e)} />
        ) : null
      }
    </Modal>
  )
}

export default RoleForm