import ProForm, {
  ProFormInstance, ProFormRadio, ProFormText
} from '@ant-design/pro-form';
import React, { useRef } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import apiurl from '../../../service/apiurl';
import { userStatus } from "../../../service/appconsts";
import { getLabelValueByObj } from '../../../utils/sysutils';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { phoneValidator } from "../../../utils/validator";
import { Form } from 'antd';
import { DefVal, ICompProps, PFormShape } from './_';
import AdcdTreeSelect from './AdcdTreeSelect';

const userStatusOptions = getLabelValueByObj(userStatus);


const DataFormNew: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {
  const { crud, pager } = ctx;

  const formRef = useRef<ProFormInstance<PFormShape>>();


  const submit = async (values: PFormShape) => {
    // debugger;
    const { _adinfo, ...params } = values;

    params.adcd = _adinfo.adcd;

    console.log(params);

    saveOrUpdateInPager(apiurl.upgramurl.user.save, params, pager, crud);
  }

  const reset = () => formRef?.current?.resetFields()

  return (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={DefVal}
        onFinish={submit}
        onReset={reset}
      >
        <ProFormText
          name="name"
          label="登陆账号"
          width={DEF_INPUT_LEN}
          placeholder="请登陆输入账号"
          rules={[{ required: true, message: '账号不能为空' }]}
        />

        <ProFormText
          name="password"
          label="密码"
          width={DEF_INPUT_LEN}
          placeholder="请输入密码"
          rules={[{ required: true, message: '密码不能为空' }]}
        />

        <ProFormText
          name="user"
          label="用户姓名"
          width={DEF_INPUT_LEN}
          placeholder="请输入用户姓名"
          rules={[{ required: true, message: '用户姓名不能为空' }]}
        />
        <ProFormText
          name="phone"
          label="联系电话"
          width={DEF_INPUT_LEN}
          rules={[{ required: true, validator: phoneValidator }]}
        />
        <ProFormText
          name="position"
          label="职务"
          width={DEF_INPUT_LEN}
        />
        <ProFormText
          name="duty"
          label="职责"
          width={DEF_INPUT_LEN}
        />
        <ProFormText
          name="org"
          label="在职单位"
          width={DEF_INPUT_LEN}
        />

        <Form.Item
          name="_adinfo"
          label="归属行政区划"
          rules={[{ required: true }]}
        >
          <AdcdTreeSelect />
        </Form.Item>

        <ProFormRadio.Group
          label="是否启用"
          name="status"
          width={DEF_INPUT_LEN}
          options={userStatusOptions}
        />
      </ProForm>
    </CenterForm>
  );
};

export default DataFormNew;