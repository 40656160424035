import { useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../models/store';
import MapTrace from './MapTrace';

const AMap = (window as any).AMap;

const GpsMap = () => {
  const mapDiv = useRef<HTMLDivElement>(null);
  const mapRef = useRef<any>();
  const [, refresh] = useReducer(s => s + 1, 0);

  const selected = useSelector((s: RootState) => s.usergps.selected);

  useEffect(() => {
    mapRef.current = new AMap.Map(mapDiv.current, {
      resizeEnable: true
    });

    refresh();

    return () => {
      if (mapRef.current) {
        mapRef.current.destroy();
      }
    }
  }, []);

  return (
    <>
      <div ref={mapDiv} style={{ height: 600, marginBottom: 16 }}>
      </div>
      <div style={{ display: 'flex', padding: 8, backgroundColor: '#fff' }}>
        {
          mapRef.current ? (
            selected.map(p => <MapTrace key={p.id} user={p} map={mapRef.current} />)
          ) : null
        }
      </div>

    </>
  )
}

export default GpsMap