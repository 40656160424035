import {
  FolderAddOutlined, PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import ProForm, { ProFormCheckbox, ProFormText } from '@ant-design/pro-form';
import { Button, Card, Divider, Modal, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import OpButton, { DelOpButton, EditOpButton, RestoreOpButton } from '../../../components/crud/OpButton';
import { CrudContext } from '../../../components/crud/useCrud';
import { PageTableContext } from '../../../components/crud/usePageTable';
import apiurl from '../../../service/apiurl';
import { delInPager, restoreInPager } from '../../../utils/utils';
import { SysRole } from '../def';
import { DEF_VALUE } from './common';


type IProps = {
  pagerCtx: PageTableContext<SysRole>;
  crudCtx: CrudContext
}

const DataTable: React.FC<IProps> = ({ pagerCtx, crudCtx }) => {
  const columns = useMemo<ColumnsType<SysRole>>(() => [
    { title: '角色', key: 'roleName', dataIndex: 'roleName' },
    { title: '描述', key: 'remark', dataIndex: 'remark' },
    { title: '创建时间', key: 'createTime', dataIndex: 'createTime' },
    {
      title: '操作', key: '_', render: rec => (
        <Space split={<Divider type="vertical" />}>
          {
            rec?.del === 0 && (
              <>
                <OpButton tooltip='权限配置' icon={<FolderAddOutlined />} onClick={
                  () => crudCtx.goto('auth',
                    { id: rec.id, ids: rec.permissionIds })} />
                <EditOpButton onClick={() => crudCtx.goto('edit', rec)} />
                <DelOpButton onClick={() => delInPager(apiurl.upgramurl.user.del, { id: rec.id }, pagerCtx)} />
              </>
            )
          }
          {
            rec?.del === 1 && (
              <RestoreOpButton onClick={() => restoreInPager(apiurl.upgramurl.role.restore, { id: rec.id }, pagerCtx)} />
            )
          }
        </Space>
      )
    }
  ], [crudCtx]);

  return (
    <>
      <Card>
        <ProForm
          layout='inline'
          onFinish={
            async (data) => {
              pagerCtx.search({
                search: {
                  roleName: data.roleName,
                  showall: data.showall ? '1' : '0',
                }
              })
            }
          }
          submitter={false}
        >
          <ProFormText label="角色名" name="roleName" />
          <ProFormCheckbox label="全部" name="showall" />
          <Space>
            <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
            <Button icon={<PlusOutlined />} onClick={() => crudCtx.goto('add', { ...DEF_VALUE })}>新建</Button>
          </Space>
        </ProForm>
      </Card>
      <div className="card-h-margin" />
      <Card>
        <Table rowKey="id" columns={columns} {...pagerCtx.tableProps} />
      </Card>
    </>
  )
};

export default DataTable
