import { Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../models/store';


const TraceInfo: React.FC = () => {

  const viewinfo = useSelector((s: RootState) => s.usergps.infoView);
  const dispatch = useDispatch<Dispatch>();

  const columns = useMemo<ColumnsType<any>>(() => [
    { title: '地址', key: 'address', dataIndex: 'address', width: '60%', ellipsis: true },
    { title: '时间', key: 'time', dataIndex: 'time', width: '40%', align: 'right' },
  ], [])

  if (!viewinfo) {
    return null;
  }

  return (
    <Modal
      visible
      title={`${viewinfo?.userName} - ${viewinfo?.time}`}
      width={600}
      onCancel={() => dispatch.usergps.setInfoView(null)}
      bodyStyle={{ padding: 0 }}
      footer={null}
    >
      <Table
        dataSource={viewinfo.path}
        columns={columns}
        rowKey="id"
        size='small'
      />
    </Modal>
  )
}

export default TraceInfo