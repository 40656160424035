import JsonApi from '../../utils/jsonapi';
import CachePromise from '../../utils/cachepromise';
import { message } from 'antd';
import apiurl from '../apiurl';
import config from '../../config';


class Service extends JsonApi {

  constructor() {
    super();

    this.cacheUserList = new CachePromise(() => this.httpget(apiurl.upgramurl.user.list), config.cacheExpireTime);
  }

  async page(params) {
    const { search, ...pageParams } = params;
    const { data, code, msg } = await this.httppost(apiurl.upgramurl.user.find, { ...search, ...pageParams }) || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return { list: [], totalRow: 0 }
    }
    return {
      list: data?.records ?? [],
      totalRow: data?.total ?? 0
    }
  }

  async list() {
    const { data, code, msg } = await this.cacheUserList.get() || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return []
    }
    return data
  }

  async findUserRoleName(roleName) {
    const { data, code, msg } = await this.httppost(apiurl.upgramurl.user.findUserRoleName, { roleName }) || {};
    if (code !== 200) {
      message.error(msg || '请求失败');
      return []
    }
    return data
  }

  async add(params) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.user.save, params) || {};
    if (code !== 200) {
      message.error(msg || '新增失败');
      return null;
    }
    message.success('新增成功');
    return true;
  }

  async edit(params) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.user.edit, params) || {};
    if (code !== 200) {
      message.error(msg || '修改失败');
      return null;
    }
    message.success('修改成功');
    return true;
  }

  async del(id) {
    const { code, msg } = await this.httppost(apiurl.upgramurl.user.del, { id }) || {};
    if (code !== 200) {
      message.error(msg || '删除失败');
      return null;
    }
    message.success('删除成功');
    return true;
  }

  async restore(id) {
    const { code, msg } = await this.httppost2(apiurl.upgramurl.user.restore, { id }) || {};
    if (code !== 200) {
      message.error(msg || '恢复失败');
      return null;
    }
    message.success('恢复成功');
    return true;
  }

  async changePwd(params) {
    const { data, code, msg } = await this.httppost(apiurl.upgramurl.user.changePwd, params) || {};
    if (code !== 200) {
      message.error(msg || '修改失败');
      return null;
    }
    message.success('修改成功，请重新登录');
    return data;
  }

  clearCache() {
    this.cacheUserList.setNull();
  }
}

const userService = new Service();
export default userService;
