import { Layout } from 'antd';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { findMenu } from '../../models/auth/menu';
import { Dispatch, RootState } from '../../models/store';
import AppBreadcrumb from './AppBreadcrumb';
import HeaderUser from './HeaderUser';
import SiderMenu from './SiderMenu';
import './style.less';
import TopMenu from './TopMenu';

const { Header, Content, Sider } = Layout;


const DashboardLayout: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  const dispatch = useDispatch<Dispatch>();

  const user = useSelector((state: RootState) => state.auth.user);
  const menu = useSelector((state: RootState) => state.auth.menu);

  useEffect(() => {
    dispatch.auth.loadMenu();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  const menuIndexes = useMemo(() => findMenu(menu, pathname), [menu, pathname]);

  return (
    <Layout className="app-root">
      <Header className="app-header">
        <img alt="..." className="app-icon" src="/assets/icons/项目管理.png" />
        <span className="app-title">湖北山洪责任人后台管理</span>
        <TopMenu menu={menu} menuIndexes={menuIndexes} />
        <div className='flex-grow-1'></div>
        <HeaderUser />
      </Header>

      <Layout>
        <Sider className="app-sider">
          <SiderMenu menu={menu} menuIndexes={menuIndexes} />
        </Sider>
        <Content className="app-content">
          <AppBreadcrumb menu={menu} menuIndexes={menuIndexes} />
          {
            user ? (
              <Suspense fallback={null}>
                <Outlet />
              </Suspense>
            ) : null
          }
        </Content>
      </Layout>
    </Layout>
  )
};

export default DashboardLayout
