import { Button, Popconfirm } from 'antd';
import React from 'react';
import { CrudContext } from './useCrud';


interface IProps {
  crudCtx: CrudContext<any>,
  confirm?: boolean,
  text?: string
}


const CancelCrud: React.FC<IProps> = ({ crudCtx, confirm, text }) => {
  text = text || '返回';
  if (confirm) {
    return (
      <Popconfirm title="是否要放弃表单返回列表"
        onConfirm={() => {
          crudCtx.goto(null, null);
        }}
      >
        <Button size="small" type="link">{text}</Button>
      </Popconfirm>
    )
  } else {
    return (
      <Button onClick={() => crudCtx.goto(null, null)} size="small" type="link">{text}</Button>
    )
  }

};

export default CancelCrud
