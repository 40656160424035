import moment from "moment";

export function renYYYYMMDD(val: any) {
  const tm = moment(val);
  if (!tm.isValid()) {
    return '-';
  }
  return tm.format('YYYY-MM-DD');
}

export function renDigitFixed2(val: any) {
  if (typeof val !== 'number') {
    return '-';
  }

  return val.toFixed(2);
}
